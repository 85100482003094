@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700,900|Yeseva+One:400');

@font-face {
  font-family: 'icomoon';
  src: url('../fonts/icomoon.eot?xx1or8');
  src: url('../fonts/icomoon.eot?xx1or8#iefix') format('embedded-opentype'),
  url('../fonts/icomoon.ttf?xx1or8') format('truetype'),
  url('../fonts/icomoon.woff?xx1or8') format('woff'),
  url('../fonts/icomoon.svg?xx1or8#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-user-remove:before {
  content: "\e91d";
}

.icon-shares:before {
  content: "\e91a";
}

.icon-comments:before {
  content: "\e91b";
}

.icon-likes:before {
  content: "\e91c";
}

.icon-email-o:before {
  content: "\e917";
}

.icon-smartphone-o:before {
  content: "\e918";
}

.icon-map-o:before {
  content: "\e919";
}

.icon-file:before {
  content: "\e916";
}

.icon-calendar-add-plain:before {
  content: "\e915";
}

.icon-calendar-add:before {
  content: "\e914";
}

.icon-price:before {
  content: "\e913";
}

.icon-calendar:before {
  content: "\e912";
}

.icon-arrow-right-double:before {
  content: "\e910";
}

.icon-arrow-left-double:before {
  content: "\e911";
}

.icon-arrow-bottom:before {
  content: "\e90e";
}

.icon-arrow-top:before {
  content: "\e90f";
}

.icon-arrow-right:before {
  content: "\e90b";
}

.icon-arrow-left:before {
  content: "\e90c";
}

.icon-dot:before {
  content: "\e90d";
}

.icon-menu:before {
  content: "\e90a";
}

.icon-user:before {
  content: "\e907";
}

.icon-user-add:before {
  content: "\e908";
}

.icon-search:before {
  content: "\e909";
}

.icon-download:before {
  content: "\e900";
}

.icon-favorite:before {
  content: "\e901";
}

.icon-map:before {
  content: "\e902";
}

.icon-print:before {
  content: "\e903";
}

.icon-email:before {
  content: "\e904";
}

.icon-facebook:before {
  content: "\e905";
}

.icon-twitter:before {
  content: "\e906";
}

@font-face {
  font-family: 'plo';
  src: url('../fonts/plo.eot?3tj3zy');
  src: url('../fonts/plo.eot?3tj3zy#iefix') format('embedded-opentype'),
  url('../fonts/plo.ttf?3tj3zy') format('truetype'),
  url('../fonts/plo.woff?3tj3zy') format('woff'),
  url('../fonts/plo.svg?3tj3zy#plo') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-plo-"], [class*=" icon-plo-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'plo' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-plo-lipad:before {
  content: "\e92b";
}

.icon-plo-sport:before {
  content: "\e92c";
}

.icon-plo-etat-civil:before {
  content: "\e900";
}

.icon-plo-subventions:before {
  content: "\e901";
}

.icon-plo-domaine-public:before {
  content: "\e902";
}

.icon-plo-enfance:before {
  content: "\e903";
}

.icon-plo-appartements:before {
  content: "\e904";
}

.icon-plo-culture:before {
  content: "\e905";
}

.icon-plo-manifestations:before {
  content: "\e906";
}

.icon-plo-materiel:before {
  content: "\e907";
}

.icon-plo-salles:before {
  content: "\e908";
}

.icon-plo-publications:before {
  content: "\e92a";
}

.icon-plo-formulaire:before {
  content: "\e909";
}

.icon-plo-taxes:before {
  content: "\e90a";
}

.icon-plo-divers:before {
  content: "\e90b";
}

.icon-plo-guichet-virtuel:before {
  content: "\e90c";
}

.icon-plo-travaux:before {
  content: "\e90d";
}

.icon-plo-agenda:before {
  content: "\e90e";
}

.icon-plo-horaires:before {
  content: "\e90f";
}

.icon-plo-projets-urbains:before {
  content: "\e910";
}

.icon-plo-conseil-municipal:before {
  content: "\e911";
}

.icon-plo-sondages:before {
  content: "\e912";
}

.icon-plo-suggestions:before {
  content: "\e913";
}

.icon-plo-cff:before {
  content: "\e914";
}

.icon-plo-offres-emploi:before {
  content: "\e915";
}

.icon-plo-urgences:before {
  content: "\e916";
}

.icon-plo-plan-ville:before {
  content: "\e917";
}

.icon-plo-dechets:before {
  content: "\e918";
}

.icon-plo-chequier-culture:before {
  content: "\e919";
}

.icon-plo-billeterie:before {
  content: "\e91a";
}

.icon-plo-benevolat:before {
  content: "\e91b";
}

.icon-plo-enfant:before {
  content: "\e91c";
}

.icon-plo-jeune:before {
  content: "\e91d";
}

.icon-plo-parents:before {
  content: "\e91e";
}

.icon-plo-retraites:before {
  content: "\e91f";
}

.icon-plo-nouvel-habitant:before {
  content: "\e920";
}

.icon-plo-association:before {
  content: "\e921";
}

.icon-plo-entreprise:before {
  content: "\e922";
}

.icon-plo-acte-etat-civil:before {
  content: "\e923";
}

.icon-plo-piece-identite:before {
  content: "\e924";
}

.icon-plo-service-communal:before {
  content: "\e925";
}

.icon-plo-marier:before {
  content: "\e926";
}

.icon-plo-maire:before {
  content: "\e927";
}

.icon-plo-cours:before {
  content: "\e928";
}

.icon-plo-logement:before {
  content: "\e929";
}

.icon-plo-site-web:before {
  content: "\e929";
}
.icon-plo-adresse:before {
  content: "\e910";
}
