.item-list {
  .pager {
    font-size: 0;
    margin: 30px auto 15px;

    li {
      display: inline-block;
      font-size: pxToRem(14px);
      margin: 0 2px;
      padding: 0;

      @include media-breakpoint-up(sm) {
        font-size: pxToRem(16px);
      }

      &.pager-item {
        a {
          color: $color-black;
        }
      }

      &.pager-current {
        color: $color-turquoise;
        padding: 7px;

        .vivre-a-plan-les-ouates & {
          color: $color-blue;
        }
        .que-faire-a-plan-les-ouates & {
          color: $color-green;
        }
        .commune &,
        .la-commune &,
        .pv-du-conseil-municipal & {
          color: $color-red;
        }
        .participer & {
          color: $color-brown;
        }
      }

      //&.pager-first, &.pager-last,
      &.pager-ellipsis {
        display: none;

        @include media-breakpoint-up(sm) {
          display: inline-block;
          padding: 7px;
        }
      }

      [class^="icon-"] {
        font-size: pxToRem(11px);

        @include media-breakpoint-up(sm) {
          font-size: pxToRem(13px);
        }
      }
    }

    a {
      display: block;
      padding: 7px;
    }
  }

}
