.node-type-page-virtual,
.page-guichet-virtuel {
  .view-id-derni_re_modification {
    display: none;
  }

  .view-id-guichet_virtuel_enfants {
    p {
      margin: 0;

      ~ p {
        margin-top: .5em;
      }
    }

    ul {
      li {
        list-style: none;
        margin-bottom: 0;
        position: relative;

        &:before {
          color: $color-turquoise;
          content: '\e90b';
          font-family: 'icomoon';
          font-size: 12px;
          left: -18px;
          position: absolute;
          top: 2px;
        }

        ~ li {
          margin-top: 1em;
        }
      }
    }
  }

  .field-name-field-introduction {
    font-weight: 600;
    margin-top: 1rem;
  }
}
