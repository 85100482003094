.page-plan-de-ma-ville,
.page-carte-des-entreprises,
.page-liste-des-entreprises,
.page-carte-des-associations {
  .region-sidebar-first .block {
    width: 100%;
  }

  .has_sub {
    background: url(../images/arrow.gif) no-repeat right 10px;
    cursor: pointer;
  }

  .hidded-once {
    //display: none;
  }

  .bef-toggle {
    display: block;
    font-size: .85rem;
    text-align: right;
  }

  .bef-tree {
    padding: 0;
    font-weight: 400;

    label {
      cursor: pointer;
    }
  }

  .bef-tree-child {
    padding-left: 16px;
  }

  .views-exposed-form label {
    font-size: pxToRem(12px);
    font-weight: $fw-regular;
  }

  .views-exposed-form .views-exposed-widget {
    float: none;
    padding: 0;
  }

  label[for="edit-categories"] {
    background-color: $color-turquoise;
    color: $color-white;
    display: block;
    font-size: pxToRem(18px);
    padding: 15px;
    margin: -15px -15px 15px;
    text-align: center;
    text-transform: uppercase;
  }

  &.vivre-a-plan-les-ouates {
    label[for="edit-categories"],
    .form-submit {
      background-color: $color-blue;
    }
  }
  &.que-faire-a-plan-les-ouates {
    label[for="edit-categories"],
    .form-submit {
      background-color: $color-green;
    }
  }
  &.commune &,
  &.la-commune {
    label[for="edit-categories"],
    .form-submit {
      background-color: $color-red;
    }
  }
  &.participer {
    label[for="edit-categories"],
    .form-submit {
      background-color: $color-brown;
    }
  }

  .form-submit {
    background: $color-turquoise none;
    border: none;
    color: $color-white;
    display: block;
    font-size: 1rem;
    font-weight: $fw-light;
    margin: 0 auto;
    padding: 8px 30px;
  }
}

.page-liste-des-entreprises,
.page-carte-des-entreprises {
  .view-id-google_map_carte_des_entreprises_et_associations {
    .view-content {
      li {
        list-style: none;
        margin-bottom: 1em;
        margin-top: 1em;
        position: relative;

        &:before {
          color: $color-turquoise;
          content: '\e90b';
          font-family: 'icomoon';
          font-size: 12px;
          left: -18px;
          position: absolute;
          top: 2px;
        }
      }
    }

    .view-filters {
      display: none;
    }

    .views-field-title {
      font-weight: $fw-semi-bold;
    }

    .views-field-field-location-category {
      font-style: italic;
      font-size: pxToRem(14px);
    }
  }
}
