.page-taxonomy {
  .view-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .term-listing-heading {
      //display: none;
    }

    .view-items {
      width: 100%;

      @include media-breakpoint-up(md) {
        display: flex;
        justify-content: stretch;
        width: 49%;
      }

      @include media-breakpoint-up(lg) {
        width: 32.4%;
      }
    }

    .node-teaser {
      background-color: $color-yellow;
      margin-bottom: 15px;

      &.node-agenda {
        background-color: $color-black;
        color: $color-white;

        a {
          color: $color-white;
        }
      }

      .node__image {
        position: relative;

        .views-field-field-event-date {
          @extend %date-block;
        }
      }

      .node__content {
        padding: 15px;

        .field-name-field-event-date {
          font-size: .8em;
          font-style: italic;
          margin-bottom: .5em;
        }

        .field-name-field-introduction {
          hyphens: auto;

          .more-link {
            margin-top: .5em;
          }
        }
      }

      .more-link {
        display: block;
        text-align: left;
      }
    }

    .item-list {
      width: 100%;
    }
  }

  &.vocabulary-location_categories {
    .view-content {
      .node-teaser.node-lieu {
        display: flex;
        flex-direction: column;
        min-height: 160px;
        padding: 15px;
        width: 100%;

        h2 {
          font-size: pxToRem(26px);
          margin-bottom: auto;
        }

        .country-name, .tel, .email, .location-hidden {
          display: none;
        }

        .street-address span {
          display: block;
        }
      }

    }
  }
}
