.node-type-agenda {
  @extend .node-type-article;

  .title {
    margin-left: 81px;
    margin-top: -45px;
  }
}

.tools-items {
  .field-name-field-event-date {
    display: inline-block;
  }
}

.agenda-header {
  display: flex;
}

.agenda-header-date {
  @extend %date-block;
  position: static;
}

.agenda-header-category {
  margin-left: 15px;
  width: 100%;

  hr {
    //background-color: $color-turquoise;
    //margin: 10px 0 0 0;

    //@include media-breakpoint-up(md) {
      display: none;
    //}
  }

  //.field-name-field-article-categories {
  //  padding-top: 20px;
  //
  //  @include media-breakpoint-up(md) {
  //    padding-top: 0;
  //  }
  //}
}
