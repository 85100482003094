#site-map {
  h2 {
    font-family: $font-family-sans-serif;
    font-size: pxToRem(24px);
    text-transform: uppercase;
  }

  span.icon {
    display: none;
  }

  ul {
    li {
      &.collapsed {
        list-style: none;
      }
    }
  }

  .site-map-menus {
    display: flex;
    flex-direction: column;
  }

  .site-map-box-menu {
    order: 3;
  }

  .site-map-box-menu-menu-guichet-virtuel {
    order: 2;
  }

  .site-map-box-menu-main-menu {
    order: 1;
  }
}
