.tabs.primary {
  border-bottom: none;
  height: auto;
  line-height: normal;
  list-style: disc;
  padding-left: 40px;
  white-space: nowrap;

  li {
    display: list-item;
    padding: 3px 0;

    &.active a,
    a {
      background: none;
      border: none;
      margin: 0;
      padding: 0;
      text-decoration: none;

      &:hover {
        background: none;
        text-decoration: underline;
      }
    }
  }
}
