.addtocal {
  align-items: center;
  background: $color-turquoise;
  color: $color-white;
  cursor: pointer;
  display: flex;
  font-size: pxToRem(14px);
  justify-content: space-between;
  margin: 0;
  padding: 0 0 0 10px;
  text-decoration: none;
  text-transform: uppercase;

  @include media-breakpoint-up(xl) {
    font-size: pxToRem(15px);
  }

  @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    height: 40px;
    position: relative;
  }

  &:after {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: $color-black;
    content: '\e914';
    font-family: icomoon;
    font-size: pxToRem(24px);
    padding: 3px 10px 1px;

    @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  .tools-items & {
    background-color: transparent;
    color: inherit;
    font-size: 0;
    padding: 0;
    position: absolute;

    &:after {
      background-color: transparent;
      color: inherit;
      content: '\e915';
      font-size: pxToRem(16px);
      margin: 0 2px;
      padding: 5px 7px 3px
    }
  }
}

ul.addtocal_menu {
  background: #efefef;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border: 1px solid #cdcdcd;
  padding: 5px;
  list-style-type: none;
  margin-top: 5px;
  margin-left: 0px !important;
  margin-bottom: 0px;
  position: absolute;
  display: none;
  min-width: 150px;
  z-index: 50;

  li {
    margin: 0px;
    padding: 0px;

    a {
      color: inherit;
      text-decoration: none;
      display: block;
      padding: 5px;

      &:hover {
        text-decoration: none;
        background: #cdcdcd;
        border-radius: 4px;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
      }
    }
  }
}
