#calendar-cff {
}

#calendar-cff #nav {
  position: relative;
  height: auto;
  min-height: 30px;
  padding: 0 0 10px;
  margin: 0;
}

#calendar-cff #nav div {
  position: absolute;
  top: 4px;
}

#calendar-cff #nav #calendar-cff-prev, #calendar-cff #nav #calendar-cff-next {
  cursor: pointer;
  height: 20px;
  width: 24px;
  z-index: 1;
}

#calendar-cff #nav #calendar-cff-prev {
  left: 0;
}

#calendar-cff #nav #calendar-cff-next {
  right: 0;
  text-align: right;
}

#calendar-cff #nav #calendar-cff-header {
  position: relative;
  width: 100%;
  z-index: 0;
  text-align: center;
}

#calendar-cff #nav #calendar-cff-header h3, #calendar-cff #nav #calendar-cff-prev a, #calendar-cff #nav #calendar-cff-next a {
  font-size: 13px;
  font-weight: bold;
  line-height: 16px;
  text-transform: none;
}

#calendar-cff table {
  display: table;
  width: 100% !important;
}

#calendar-cff table tr th, #calendar-cff table tr td {
  text-align: center;
  text-transform: uppercase;
  border: 0 none;
  padding: 3px;
}

#calendar-cff {
  td {
    position: relative;

    &.future, &.today {
      background-color: $color-red;
      color: $color-white;
    }

    &.with-pass {
      background-color: $color-green;
    }

    &.hok {
      height: 47px !important;
    }

    .ajax-progress {
      right: 0;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      z-index: 999;
    }

    a {
      color: $color-white;
    }

    a {
      display: block;
      padding: 8px 0;
    }
  }
}

.cff-box {
  text-align: center;

  h4 {
    margin-top: 1em;
  }
}

.cff-box-add {
  a {
    background: $color-turquoise;
    color: $color-white;
    display: inline-block;
    font-size: 1rem;
    font-weight: 300;
    margin: 1em auto 0;
    padding: 8px 30px;
    text-decoration: none;
  }
}

.cart-block-items {
  font-size: pxToRem(13px);
  margin-bottom: .5em;

  .cart-block-item-price {
    vertical-align: bottom;
  }
}

.cart-block-summary {
  font-size: pxToRem(14px);
}

.cart-block-summary-links {
  ul.links {
    padding: 0;

    td {
      text-align: center;
    }
    li {
      border: none;
    }
    a {
      background: $color-turquoise;
      color: $color-white;
      display: block;
      font-size: 1rem;
      font-weight: 300;
      padding: 8px 30px;
      text-align: center;
      text-decoration: none;

      .vivre-a-plan-les-ouates & {
        background-color: $color-blue;
      }
      .que-faire-a-plan-les-ouates & {
        background-color: $color-green;
      }
      .commune &,
      .la-commune &,
      .pv-du-conseil-municipal & {
        background-color: $color-red;
      }
      .participer & {
        background-color: $color-brown;
      }
    }
  }
}

#uc-cart-view-form,
#uc-cart-checkout-form {
  tr {
    background-color: transparent;
  }

  .form-actions {
    display: flex;
    flex-direction: column;

    @include media-breakpoint-up(md) {
      flex-direction: row;
      justify-content: center;
    }

    .form-submit {
      background: $color-turquoise;
      border: none;
      color: $color-white;
      font-size: 1rem;
      font-weight: 300;
      margin: 15px auto 0;
      min-width: 270px;
      padding: 8px 15px;
      text-align: center;
      text-decoration: none;

      @include media-breakpoint-up(md) {
        min-width: auto;
        margin: 15px 0 0;

        & ~ .form-submit {
          margin-left: 30px;
        }
      }
    }
  }

  td.remove {
    padding: 3px 6px 3px 3px;
    position: relative;
    vertical-align: middle;

    &:after {
      background-color: $color-red;
      color: $color-white;
      content: 'X';
      font-size: pxToRem(10px);
      pointer-events: none;
      position: absolute;
      left: 5px;
      top: 50%;
      width: 21px;
      height: 20px;
      line-height: 20px;
      text-align: center;
      margin-top: -11px;
    }

    .form-submit {
      border: none;
      color: $color-white;
      height: 19px;
      width: 19px;
      overflow: hidden;
      padding: 0;
    }
  }

  td.desc, td.qty, td.price {
    vertical-align: middle;
  }

  td.desc {
    width: 100% !important;

    a {
      color: $color-black;
      pointer-events: none;
    }
  }

  td.qty {
    input {
      background-color: $color-gray-lighter;
      border: none;
      padding: 3px 6px;
      text-align: right;
    }
  }

  td.subtotal {
    padding: .5rem 0;
  }

  .form-text {
    margin: .25rem;
    max-width: 100%;
  }
}

#block-uc-cart-cart {
  display: none;

  @include media-breakpoint-up(lg) {
    display: block;
  }
}

#uc-cart-checkout-form {
  fieldset{
    ~ fieldset {
      margin-top: 30px;
    }
  }

  .title {
    font-size: pxToRem(16px);
  }

  .form-item {
    label {
      float: none;
      margin: 0;
      padding-bottom: 0;
      text-align: left;
      width: 15em;

      @include media-breakpoint-up(md) {
        float: left;
        padding: 12px 6px 0;
        text-align: right;
      }
    }
  }

  .form-type-radio {
    label {
      float: none;
    }

    #paypal-includes {
      padding-left: 21px;
    }
  }

  .form-type-textarea {
    label {
      float: none;
      width: auto;
    }
  }

  #line-items-div {
    float: none;
    margin: 0 0 15px 0;

    tbody {
      border: none;
    }
  }

  .form-text,
  .form-textarea {
    border: 1px solid $color-gray;
    padding: 6px;
  }
}

.order-review-table{
  border: none;
  width: 100%;

  tr {
    background-color: transparent;
    font-size: pxToRem(13px);
  }

  .pane-title-row {
    background-color: transparent;
    border: none;
    font-size: pxToRem(24px);
    font-weight: $fw-regular;
    text-align: left;

    td {
      padding-top: 30px;
    }
  }

  .review-button-row {
    background-color: $color-white;
    border: none;

    div, form {
      display: block;

      @include media-breakpoint-up(sm) {
        display: inline-block;
      }
    }

    td {
      text-align: center;

      @include media-breakpoint-up(sm) {
        text-align: right;
      }
    }
  }

  .row-border-top {
    border: none;
  }

  table.cart-review {
    width: 100%;
  }

  .title-col,
  .data-col {
    padding: 6px 0;
  }

  .title-col {
    padding-right: 3px;
  }

  .data-col {
    padding-left: 3px;
    width: 100%;
  }

  .form-actions {
    display: inline-block !important;
    margin: 0;
    text-align: center;

    .form-submit {
      background: $color-turquoise;
      border: none;
      color: $color-white;
      font-size: 1rem;
      font-weight: 300;
      margin: 15px 15px 0;
      min-width: 270px;
      padding: 8px 30px;
      text-align: center;
      text-decoration: none;

      @include media-breakpoint-up(sm) {
        min-width: auto;
      }
    }
  }
}

#uc-cart-view-form table {
  display: table;
  width: 100% !important;
}

#uc-cart-view-form .form-actions a {
  float: none;
  background: $color-turquoise;
  border: none;
  color: $color-white;
  font-size: 1rem;
  font-weight: 300;
  margin: 15px auto 0;
  min-width: 270px;
  padding: 8px 15px;
  cursor: default;
  text-align: center;
  text-decoration: none;

  @include media-breakpoint-up(md) {
    min-width: auto;
    margin: 15px 0 0;

    & ~ .form-submit {
      margin-left: 30px;
    }
  }
}
