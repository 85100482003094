.region-footer-before-second {
  /**
  Les plus
   */
  #block-menu-block-10 {
    background-color: $color-white;
    margin: 0 -15px;
    padding: 30px 15px;

    h2 {
      position: relative;
      margin-bottom: 25px;
      text-align: center;

      &:after {
        border-bottom: 1px solid $color-turquoise;
        border-top: 1px solid $color-turquoise;
        bottom: -10px;
        content: '';
        display: block;
        height: 5px;
        left: 50%;
        position: absolute;
        transform: translateX(-50%);
        width: 120px;
      }
    }

    li {
      color: $color-white;
      background-color: $color-black;
      margin-top: 1px;
      text-transform: uppercase;
      width: 100%;

      @include media-breakpoint-up(sm) {
        &:nth-of-type(odd) {
          background-color: $color-turquoise;
        }
      }
    }

    a {
      align-items: center;
      color: inherit;
      display: flex;
      padding: 10px;

      &:hover {
        text-decoration: none;
      }

      @include media-breakpoint-up(sm) {
        flex-direction: column;
        font-size: pxToRem(14px);
        line-height: 1.2;
        padding: 30px 15px;
        position: relative;
        text-align: center;
      }

      @include media-breakpoint-up(lg) {
        font-size: pxToRem(16px);
      }

      @include media-breakpoint-up(xl) {
        font-size: pxToRem(18px);
      }
    }

    .menu {
      @include media-breakpoint-up(sm) {
        display: flex;
      }
    }

    .icon {
      font-size: 36px;
      margin-right: 10px;

      @include media-breakpoint-up(sm) {
        color: $color-white;
        font-size: 50px;
        margin-bottom: 30px;
        margin-right: 0;
      }
    }
  }
}

/**
Footer
 */
#footer {
  color: $color-white;
  overflow-x: hidden;

  @include media-breakpoint-up(md) {
    background-color: $color-black;
    padding-top: 15px;
  }

  > .container {
    @include media-breakpoint-down(sm) {
      max-width: none;
      padding: 0;
    }
  }

  .region {
    h2 {
      font-family: $font-family-sans-serif;
      font-size: 24px;
      font-weight: $fw-semi-bold;
      position: relative;
      margin-bottom: 25px;
      text-transform: uppercase;

      @include media-breakpoint-up(md) {
        text-align: left;
      }

      &:after {
        border-bottom: 1px solid $color-turquoise;
        border-top: 1px solid $color-turquoise;
        bottom: -10px;
        content: '';
        display: block;
        height: 5px;
        left: 50%;
        position: absolute;
        transform: translateX(-50%);
        width: 120px;

        @include media-breakpoint-up(md) {
          left: 0;
          transform: none;
        }
      }
    }
  }

  .region-footer-first {
    @include media-breakpoint-down(sm) {
      background-color: $color-yellow;
      color: $color-black;
      padding: 15px 0;
      text-align: center;

      p {
        &:last-of-type {
          margin-bottom: 0;
        }
      }

      .icon {
        font-size: pxToRem(20px);
      }
    }
  }

  .region-footer-second {
    @include media-breakpoint-down(sm) {
      background-color: $color-black;
      padding: 15px 0 1px;
      text-align: center;
    }
  }

  .region-footer-third {
    @include media-breakpoint-down(md) {
      text-align: center;
    }
  }

  a {
    color: $color-black;

    @include media-breakpoint-up(md) {
      color: $color-white;
    }
  }
}

/**
Copyright
 */
#copyright {
  overflow-x: hidden;
  
  @include media-breakpoint-up(md) {
    background-color: $color-gray-dark;
    color: $color-white;
  }

  > .container {
    @include media-breakpoint-down(sm) {
      max-width: none;
      padding: 0;
    }
  }

  .region-copyright-first {
    padding: 0;
    background-color: $color-yellow;

    @include media-breakpoint-up(md) {
      background-color: transparent !important;
      padding: 8px 0;

    }

    .vivre-a-plan-les-ouates & {
      background-color: $color-blue-light;
    }
    .que-faire-a-plan-les-ouates & {
      background-color: $color-green-light;
    }
    .commune &,
    .la-commune &,
    .pv-du-conseil-municipal & {
      background-color: $color-red-light;
    }
    .participer & {
      background-color: $color-brown-light;
    }

    ul {
      display: flex;
      flex-wrap: wrap;
      list-style: none;
      margin: 0;
      padding: 0;
    }

    li {
      font-size: pxToRem(11px);
      text-align: center;
      width: 50%;
      display: flex;
      justify-content: center;
      align-items: center;

      @include media-breakpoint-up(sm) {
        width: 20%;
      }

      @include media-breakpoint-up(md) {
        border: none !important;
        width: auto;
      }

      @include media-breakpoint-up(lg) {
        font-size: pxToRem(12px);
      }

      &:first-of-type, &:nth-of-type(2) {
        border-bottom: 1px solid $color-white;

        @include media-breakpoint-up(sm) {
          border-bottom: none;
        }
      }

      &:first-of-type, &:nth-of-type(3) {
        border-right: 1px solid $color-white;
      }

      &:nth-of-type(2) {
        @include media-breakpoint-up(sm) {
          border-right: 1px solid $color-white;
        }
      }

      @include media-breakpoint-up(md) {
        ~ li {
          &:before {
            content: '|';
            padding: 0 3px;
          }
        }
      }

    }

    a {
      display: block;
      padding: 15px;
      color: $color-white;

      @include media-breakpoint-up(md) {
        display: inline;
        padding: 0;
      }

      @include media-breakpoint-down(sm) {
        color: $color-turquoise;

        .vivre-a-plan-les-ouates & {
          color: $color-blue;
        }
        .que-faire-a-plan-les-ouates & {
          color: $color-green;
        }
        .commune &,
        .la-commune &,
        .pv-du-conseil-municipal & {
          color: $color-red;
        }
        .participer & {
          color: $color-brown;
        }
      }
    }
  }

  .region-copyright-second {
    background-color: $color-gray-dark;
    color: $color-white;
    font-size: pxToRem(11px);
    padding: 8px;
    text-align: center;

    @include media-breakpoint-up(md) {
      padding: 8px 0;
      text-align: right;
    }

    @include media-breakpoint-up(lg) {
      font-size: pxToRem(12px);
    }

    p {
      margin: 0;

      @include media-breakpoint-up(md) {
        display: inline;
      }
    }

    img {
      width: 25px;

      @include media-breakpoint-up(md) {
        margin-left: 15px;
      }

      @include media-breakpoint-only(md) {
        width: 20px;
      }
    }

    .media-element-container {
      @include media-breakpoint-up(md) {
        &,
        div {
          display: inline;

        }
      }
    }
  }
}
