/**
Articles and events search block
 */


#block-views-4c4eddd4d776840e5150ff6987ed931d, // Sidebar
#block-views-exp-articles-page,                // In-page
#block-views-exp-agenda-page,                  // In-page
#views-exposed-form-google-map-carte-des-entreprises-et-associations-page-1,
#block-views-exp-activites-page {              // In-page

  margin-bottom: 15px;

  label {
    display: none;
  }

  .views-exposed-widgets {
    font-size: 0;
  }

  .views-exposed-widget {
    float: none;
    font-size: 0;
    padding: 0;

    ~ .views-exposed-widget {
      margin-top: 15px;
    }
  }

  .views-widget-filter-field_tags_tid,
  .views-widget-filter-keys,
  .views-widget-filter-field_event_date_value, .views-widget-filter-field_event_date_value2 {
    width: 100%;

    input {
      background: $color-white none;
      border: none;
      color: $color-black;
      font-size: pxToRem(16px);
      font-weight: $fw-light;
      margin: 0;
      max-width: 100%;
      padding: 8px 30px 8px 10px;
      width: 100%;

      &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        color: $color-black;
      }
      &::-moz-placeholder { /* Firefox 19+ */
        color: $color-black;
      }
      &:-ms-input-placeholder { /* IE 10+ */
        color: $color-black;
      }
      &:-moz-placeholder { /* Firefox 18- */
        color: $color-black;
      }
    }
  }

  .views-exposed-form .container-inline-date .date-padding,
  .container-inline-date .form-item {
    width: 100%;
  }

  .views-submit-button {
    input {
      background: $color-turquoise none;
      border: none;
      color: $color-white;
      display: block;
      font-size: pxToRem(16px);
      font-weight: $fw-light;
      margin: 0 auto;
      padding: 8px 30px;
    }
  }
}

/**
 * Fields
 */
.views-widget-filter-field {

    /**
     * <select>
     */
    &_article_categories_tid,
    &_event_target_audienc_tid,
    &_activite_public_cible_tid,
    &_activite_lieu_tid,
    &_activite_categorie_tid {

        position: relative;

        &:after {

            -webkit-font-smoothing:  antialiased;
            -moz-osx-font-smoothing: grayscale;
            background-color:        $color-turquoise;
            color:                   $color-white;
            content:                 '\e90e';
            font:                    22px "icomoon";
            padding:                 9px;
            pointer-events:          none;
            position:                absolute;
            right:                   0;
            top:                     0;
        }

        select {

            -webkit-appearance: none;
            -moz-appearance:    none;
            appearance:         none;
            background:         $color-white none;
            border:             none;
            border-radius:      0;
            color:              $color-black;
            display:            inline-block;
            font-size:          pxToRem(16px);
            font-weight:        $fw-light;
            margin:             0;
            padding:            8px 40px 8px 10px;
            width:              100%;
            -ms-word-break:     normal;
            word-break:         normal;

            &::-ms-expand {
                display: none;
            }
        }
    }

    &_article_categories_tid,
    &_event_target_audienc_tid,
    &_activite_public_cible_tid,
    &_activite_lieu_tid,
    &_activite_categorie_tid {

      @include media-breakpoint-up(md) {
        min-width: 200px;
      }

      &:after {
        background-color: $color-black;
      }
    }

    &_article_categories_tid,
    &_event_target_audienc_tid,
    &_event_date_value2,
    &_event_date_value {
      @include media-breakpoint-up(md) {
        min-width: auto;
        width: calc(25% - 12px);
      }
    }

    &_activite_categorie_tid,
    &_activite_lieu_tid,
    &_activite_public_cible_tid {
      @include media-breakpoint-up(md) {
        min-width: auto;
        width: calc(33% - 12px);
      }
    }
}


#block-views-exp-articles-page,
#block-views-exp-agenda-page,
#views-exposed-form-google-map-carte-des-entreprises-et-associations-page-1,
#block-views-exp-activites-page {

  .views-exposed-widgets {
    background-color: $color-turquoise;
    padding: 15px;
  }


  .views-submit-button {
    input {
      background-color: $color-black;
    }
  }

  @include media-breakpoint-up(md) {
    .views-exposed-widgets {
      display: flex;
    }

    .views-exposed-widget {
      ~ .views-exposed-widget {
        margin-top: 0;
        margin-left: 15px;
      }
    }
  }
}

#block-views-exp-agenda-page,
#block-views-exp-activites-page {

  .views-exposed-widgets {
    @include media-breakpoint-up(md) {
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }

  .views-exposed-widget {
    ~ .views-exposed-widget {
      @include media-breakpoint-up(md) {
        margin-left: 0;
      }
    }
  }


  .views-widget-filter-field_event_date_value2,
  .views-widget-filter-field_event_date_value {
    position: relative;

    @include media-breakpoint-up(md) {
      min-width: auto;
      width: calc(25% - 12px);
    }

    &:after {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      background-color: $color-black;
      color: $color-white;
      content: '\e912';
      font: 22px "icomoon";
      padding: 9px;
      pointer-events: none;
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  .views-widget-filter-field_tags_tid,
  .views-widget-filter-keys {
    @include media-breakpoint-up(md) {
      margin-top: 15px !important;
      width: calc(75% - 5px);
    }
  }

  .views-submit-button {
    @include media-breakpoint-up(md) {
      margin-left: 15px !important;
      margin-top: 15px !important;
      width: calc(25% - 12px);

      input {
        margin: 0;
        width: 100%;
      }
    }
  }

}

#views-exposed-form-google-map-carte-des-entreprises-et-associations-page-1 {
    .views-widget-filter-keys {
      @include media-breakpoint-up(md) {
        width: calc(33% - 5px);
      }
    }
}

#block-user-login {
  margin-bottom: 15px;

  label {
    display: none;
  }

  .form-type-textfield,
  .form-type-password {
    width: 100%;

    input {
      background: $color-white none;
      border: none;
      color: $color-black;
      font-size: pxToRem(16px);
      font-weight: $fw-light;
      margin: 0;
      max-width: 100%;
      padding: 8px 30px 8px 10px;
      width: 100%;

      &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        color: $color-black;
      }
      &::-moz-placeholder { /* Firefox 19+ */
        color: $color-black;
      }
      &:-ms-input-placeholder { /* IE 10+ */
        color: $color-black;
      }
      &:-moz-placeholder { /* Firefox 18- */
        color: $color-black;
      }
    }
  }

  input[type=submit] {
    background: $color-turquoise none;
    border: none;
    color: $color-white;
    display: block;
    font-size: pxToRem(16px);
    font-weight: $fw-light;
    margin: 0 auto;
    padding: 8px 30px;
  }

  #user-login-block-form-fields {
    .item-list {
      display: none;
    }
  }
}

/**
PV
 */
.view-id-pv {
  label {
    display: none;
  }

  .views-exposed-widgets {
    background-color: $color-red;
    font-size: 0;
    padding: 15px;

    @include media-breakpoint-up(md) {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }

  .views-exposed-widget {
    float: none;
    font-size: 0;
    padding: 0;

    ~ .views-exposed-widget {
      margin-top: 15px;

      @include media-breakpoint-up(md) {
        margin-left: 0;
        margin-top: 0;
      }
    }
  }

  .views-widget-filter-field_taxo_pv_tid {
    position: relative;

    @include media-breakpoint-up(md) {
      min-width: 200px;
    }

    &:after {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      background-color: $color-black;
      color: $color-white;
      content: '\e90e';
      font: 22px "icomoon";
      padding: 9px;
      pointer-events: none;
      position: absolute;
      right: 0;
      top: 0;
    }

    select {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      background: $color-white none;
      border: none;
      border-radius: 0;
      color: $color-black;
      display: inline-block;
      font-size: pxToRem(16px);
      font-weight: $fw-light;
      margin: 0;
      padding: 8px 40px 8px 10px;
      width: 100%;
      -ms-word-break: normal;
      word-break: normal;

      &::-ms-expand {
        display: none;
      }
    }
  }

  .views-widget-filter-field_numero_pv_value,
  .views-widget-filter-keys,
  .views-widget-filter-date_filter,
  .views-widget-filter-date_filter_1 {
    width: 100%;

    input {
      background: $color-white none;
      border: none;
      color: $color-black;
      font-size: pxToRem(16px);
      font-weight: $fw-light;
      margin: 0;
      max-width: 100%;
      padding: 8px 30px 8px 10px;
      width: 100%;

      &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        color: $color-black;
      }
      &::-moz-placeholder { /* Firefox 19+ */
        color: $color-black;
      }
      &:-ms-input-placeholder { /* IE 10+ */
        color: $color-black;
      }
      &:-moz-placeholder { /* Firefox 18- */
        color: $color-black;
      }
    }
  }

  .views-widget-filter-field_numero_pv_value,
  .views-widget-filter-field_taxo_pv_tid,
  .views-widget-filter-date_filter,
  .views-widget-filter-date_filter_1 {
    @include media-breakpoint-up(md) {
      min-width: auto;
      width: calc(25% - 12px);
    }
  }

  .views-widget-filter-date_filter,
  .views-widget-filter-date_filter_1 {
    position: relative;

    &:after {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      background-color: $color-black;
      color: $color-white;
      content: '\e912';
      font: 22px "icomoon";
      padding: 9px;
      pointer-events: none;
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  .views-widget-filter-keys {
    @include media-breakpoint-up(md) {
      margin-top: 15px !important;
      width: calc(75% - 5px);
    }
  }

  .views-exposed-form .container-inline-date .date-padding,
  .container-inline-date .form-item {
    width: 100%;
  }

  .views-submit-button {
    @include media-breakpoint-up(md) {
      margin-left: 15px !important;
      margin-top: 15px !important;
      width: calc(25% - 12px);

      input {
        margin: 0;
        width: 100%;
      }
    }

    input {
      background: $color-black none;
      border: none;
      color: $color-white;
      display: block;
      font-size: pxToRem(16px);
      font-weight: $fw-light;
      margin: 0 auto !important;
      padding: 8px 30px;
    }
  }
}

/**
Webforms
 */
.webform-client-form {
  label {
    color: $color-turquoise;
  }

  input[type=radio],
  input[type=checkbox] {
    ~ label {
      color: inherit;
      font-size: pxToRem(14px);
    }
  }

  input[type=text], input[type=email], input[type=search],
  textarea {
    background: $color-turquoise-light none;
    border: none;
    color: $color-black;
    font-size: pxToRem(14px);
    font-weight: $fw-light;
    margin: 0;
    max-width: 500px;
    padding: 8px 10px;
    width: 100%;
  }

  .form-textarea-wrapper {
    max-width: 500px;
  }

  .webform-component-select {
    display: flex;
    flex-wrap: wrap;

    &:after {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      background-color: $color-turquoise;
      color: $color-white;
      content: '\e90e';
      cursor: pointer;
      font: 21px "icomoon";
      padding: 8px;
      pointer-events: none;
    }

    label {
      width: 100%;
    }
  }

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: $color-turquoise-light none;
    border: none;
    border-radius: 0;
    color: $color-black;
    font-size: pxToRem(14px);
    font-weight: $fw-light;
    margin: 0 -37px 0 0;
    padding: 7px 47px 6px 10px;
    -ms-word-break: normal;
    word-break: normal;

    &::-ms-expand {
      display: none;
    }
  }

  .webform-component-date,
  .webform-component-time {
    .form-type-select {
      display: flex;
      flex-wrap: wrap;

      &:after {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        background-color: $color-turquoise;
        color: $color-white;
        content: '\e90e';
        cursor: pointer;
        font: 21px "icomoon";
        padding: 8px;
        pointer-events: none;
      }
    }
  }

  .webform-component-date {
    .webform-datepicker,
    .webform-container-inline {
      display: flex;
      align-items: center;
    }

    .form-type-select {
      ~ * {
        margin-left: 10px;
      }
    }
  }

  .webform-component-time {
    .webform-container-inline {
      display: flex;
      align-items: center;

      .form-type-select {
        margin-right: 10px;
      }

      .form-type-select:nth-child(2) {
        margin-left: 10px;
      }
    }
  }

  .webform-component-grid {
    overflow: scroll;
    table {
      font-size: pxToRem(14px);
    }
    th {
      border-bottom-color: $color-turquoise;
    }
    tr {
      background-color: $color-turquoise-light;
      border-bottom-color: $color-turquoise;
    }
  }

  div[style^="display"] > div[id^="edit-submitted"] {
    .webform-component-file .form-managed-file {
      &::before {
        //display: none;
        border-right-width: 0;
        height: 0;
        overflow: hidden;
        padding: 0;
        width: 0;
      }

      span.file {
        padding: 8px 15px 0 0;
      }
    }
    .messages {
      &.error {
        background-color: $color-red-lighter;
        border-color: $color-red-light;
        color: $color-red;
        max-width: 500px;
      }

      ~ .webform-component-file .form-managed-file {
        &::before {
          //display: block;
          border-right-width: 20px;
          height: auto;
          overflow: auto;
          background: $color-turquoise none;
          padding: 8px 30px;
          width: auto;
        }

        span.file {
          padding: 8px 15px 0 0;
        }
      }
    }
  }

  .webform-component-multiple-file,
  .webform-component-file {
    input[type=submit] {
      background: $color-turquoise none;
      border: none;
      color: $color-white;
      font-size: pxToRem(16px);
      font-weight: $fw-light;
      padding: 8px 30px;
    }

    .form-file {
      width: 194px;
      margin-right: 20px;
      margin-bottom: 30px;

      @include media-breakpoint-up(sm) {
        margin-bottom: 0;
      }
    }

    .form-managed-file {
      position: relative;

      .messages {
        position: absolute;
        top: 110px;

        @media screen and (min-width: 400px) {
          top: 55px;
        }

        ~ input[type=submit] {
          margin-bottom: 215px;

          @media screen and (min-width: 400px) {
            margin-bottom: 190px;
          }

          @include media-breakpoint-up(sm) {
            margin-bottom: 140px;
          }

          @include media-breakpoint-up(md) {
            margin-bottom: 115px;
          }
        }
      }

      @include media-breakpoint-up(sm) {
        //align-items: flex-start;
        display: flex;
      }

      &::before {
        content: 'Choisir un fichier';
        display: inline-block;
        outline: none;
        //white-space: nowrap;
        user-select: none;
        cursor: pointer;
        //visibility: visible;
        pointer-events: none;
        position: absolute;

        background: $color-turquoise none;
        border: none;
        border-right: 20px solid $color-white;
        color: $color-white;
        font-size: pxToRem(16px);
        font-weight: $fw-light;
        padding: 8px 30px;
        top: 0;
        left: 0;
      }
    }
  }

  .webform-component-multiple-file {
    margin-top: 0;
  }

  .tabledrag-toggle-weight-wrapper {
    display: none;
  }

  table.tabledrag-processed {
    margin-bottom: 15px;
    width: 100%;

    thead {
      th {
        font-size: pxToRem(14px);
      }
    }

    td {
      vertical-align: middle;
    }

    input[type=submit] {
      background: $color-red none;
      border: none;
      color: $color-white;
      font-size: pxToRem(14px);
      font-weight: $fw-light;
      margin: 3px;
      padding: 3px 6px;
    }

    .draggable {
      td:last-of-type {
        text-align: right;
      }
    }

    .fieldset-legend {
      font-size: pxToRem(18px);
    }
  }

  .webform-component-fieldset {
    legend {
      margin: 0;
    }

    .fieldset-legend {
      font-size: pxToRem(18px);
    }
  }

  .form-actions {
    input {
      background: $color-black none;
      border: none;
      color: $color-white;
      cursor: pointer;
      display: block;
      font-size: pxToRem(16px);
      font-weight: $fw-light;
      padding: 8px 30px;
    }
  }
}
