$fw-light: 300;
$fw-regular: 400;
$fw-medium: 500;
$fw-semi-bold: 600;
$fw-bold: 700;
$fw-extra-bold: 900;

// Colors system
$color-black: #313237;
$color-gray: #acacae;
$color-gray-lighter: #efefef;
$color-gray-dark : #606165;
$color-white: #fff;

$color-blue: #668a9f; //Vivre à PLO
$color-blue-light: #e0e8ec;
$color-blue-lighter: #F3F6F7;

$color-turquoise: #509CA2;
$color-turquoise-light: #DCEBEC;

$color-green: #7b9d7e; //Que faire à PLO
$color-green-light: #ebf0eb;
$color-green-lighter: #f2f7f2;

$color-red: #9d5769; //Commune
$color-red-light: #f0e6e8;
$color-red-lighter: #fff5f7;

$color-brown: #b69474; //Commune
$color-brown-light: #f4efea;
$color-brown-lighter: #fef9f4;

$color-yellow: #fcf6f1;

// Slick carousel
$slick-font-path: "./../fonts/";
$slick-font-family: "icomoon";
$slick-loader-path: "./../images/";
$slick-arrow-color: $color-black;
$slick-dot-color: $color-turquoise;
$slick-dot-color-active: $color-black;
$slick-prev-character: "\e90c";
$slick-next-character: "\e90b";
$slick-dot-character: "\e90d";
$slick-dot-size: 12px;
$slick-opacity-default: 1;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: 1;

// Options
$enable-rounded: false;

// Body

$body-bg: $color-gray-lighter;
$body-color: $color-black;

// Links
$link-color: $color-turquoise;

// Fonts
$font-family-sans-serif: 'Montserrat', sans-serif;
$headings-font-family: 'Yeseva One', serif;
