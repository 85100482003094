.tb-megamenu {
  hyphens: auto;
}

.tb-megamenu-nav {
  display: flex;
}

.tb-megamenu-button {
  display: none;
}

@include media-breakpoint-down(md) {
  .tb-megamenu {
    margin-left: -15px;
    margin-right: -15px;
    position: relative;
    z-index: 999;

    .nav-collapse {
      display: none;
      position: absolute;
      width: 100%;

      &.show-menu {
        display: block;
      }
    }

    .dropdown-toggle {
      display: block;
      padding: 15px 33px 15px 15px;
    }
  }

  .tb-megamenu-nav {
    flex-direction: column;
    border-bottom: 1px solid $color-black;
  }

  .tb-menu-accueil,
  .tb-menu-vivre,
  .tb-menu-faire,
  .tb-menu-commune,
  .tb-menu-participer {
    > a {
      background-color: $color-white;
      font-weight: $fw-bold;
    }
  }

  .tb-menu-accueil {
    > a {
      color: $color-turquoise;
    }
  }

  .tb-menu-vivre {
    > a {
      color: $color-blue;
    }

    .tb-megamenu-item {
      &.level-2 {
        > a {
          background-color: $color-blue;
        }
      }

      &.level-3 {
        border-top-color: $color-blue;

        > a {
          color: $color-blue;
          background-color: $color-blue-light;
        }
      }
    }
  }

  .tb-menu-faire {
    > a {
      color: $color-green;
    }

    .tb-megamenu-item {
      &.level-2 {
        > a {
          background-color: $color-green;
        }
      }

      &.level-3 {
        border-top-color: $color-green;

        > a {
          color: $color-green;
          background-color: $color-green-light;
        }
      }
    }
  }

  .tb-menu-commune {
    > a {
      color: $color-red;
    }

    .tb-megamenu-item {
      &.level-2 {
        > a {
          background-color: $color-red;
        }
      }

      &.level-3 {
        border-top-color: $color-red;

        > a {
          color: $color-red;
          background-color: $color-red-light;
        }
      }
    }
  }

  .tb-menu-participer {
    > a {
      color: $color-brown;
    }

    .tb-megamenu-item {
      &.level-2 {
        > a {
          background-color: $color-brown;
        }
      }

      &.level-3 {
        border-top-color: $color-brown;

        > a {
          color: $color-brown;
          background-color: $color-brown-light;
        }
      }
    }
  }

  .tb-menu-guichet-virtuel {
    background-color: $color-black;

    .leaf {
      border-top: 1px solid $color-white;

      a {
        color: $color-white;
        display: flex;
        padding: 12px 15px;
        align-items: center;
        justify-content: space-between;

        span {
          order: 2;
          font-size: 30px;
        }
      }
    }
  }

  .tb-megamenu-item {
    border-top: 1px solid $color-black;

    > .dropdown-menu {
      border: none;
      margin: 0;
      padding: 0;
      width: 100%;

      ul {
        list-style: none;
        padding: 0;
      }
    }

    &.open {
      > .dropdown-menu {
        display: block;
        position: relative;
      }

      > .dropdown-toggle {
        @include caret(up);
      }
    }

    &.level-1 {
      .dropdown-toggle {
        position: relative;

        &:after {
          position: absolute;
          right: 15px;
          top: 25px;
        }
      }
    }

    &.level-2 {
      border-top-color: $color-white;

      > a {
        background-color: $color-blue;
        color: $color-white;
        display: block;
        padding: 15px 33px 15px 15px;
      }

      .dropdown-menu {
        margin-bottom: 15px;
      }
    }

    &.level-3 {
      border-top-color: $color-blue;

      > a {
        background-color: $color-turquoise-light;
        color: $color-blue;
        display: block;
        padding: 15px 33px 15px 15px;
        text-transform: none;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .tb-megamenu {
    .nav-collapse {
      display: block;
    }
  }

  .tb-megamenu-button {
    display: none;
  }

  .tb-megamenu-nav {
    justify-content: space-between;
  }

  .tb-megamenu-subnav {
    &.level-1 {
      display: flex;
      flex-wrap: wrap;
      list-style: none;
      padding: 0;
    }

    &.level-2 {
      list-style: none;
      padding: 0;
    }
  }

  .tb-megamenu-item {
    &, a {
      color: $color-black;
      font-size: pxToRem(13px);
    }

    &.level-1 {
      &.open {
        > .dropdown-menu {
          display: block;
        }
      }

      > .dropdown-menu {
        border: none;
        margin: 22px 0 0 -240px;
        max-width: 960px;
        padding: 0;
        width: 150%;
      }

      > a {
        display: block;
        margin-top: 5px;
        padding: 15px 0;
      }
    }

    &.level-2 {
      padding: 15px;
      width: 220px;

      > a {
        display: inline-block;
        font-weight: $fw-semi-bold;
        margin-bottom: .5em;
      }

      .dropdown-menu {
        background-color: inherit;
        border: none;
        display: block;
        float: none;
        margin: 0;
        padding: 0;
        position: relative;
      }
    }

    &.level-3 {
      text-transform: none;

      > a {
        display: inline-block;
        font-size: .9em;
        font-weight: $fw-light;
        line-height: 1.25;
        margin-bottom: 3px;
      }

      > .tb-megamenu-submenu {
        display: none;
      }
    }
  }

  .tb-megamenu-row {
    display: flex;
  }

  .tb-megamenu-row > div:first-of-type,
  .tb-menu-links {
    //padding: 15px;
    width: 75%;
  }

  .tb-menu-guichet-virtuel {
    padding: 15px 30px;
    width: 25%;

    .leaf {
      border-top: 1px solid $color-blue;

      &.first {
        border: none;
      }

      a {
        display: flex;
        padding: 12px 0;
        align-items: center;

        span {
          font-size: 30px;
          margin-right: 15px;
        }
      }
    }

    .icon {
      padding: 5px;
    }
  }

  .tb-menu-vivre {
    .tb-megamenu-row > div:first-of-type,
    .tb-menu-links {
      background-color: $color-blue;
    }

    .tb-menu-guichet-virtuel {
      background-color: $color-blue-light;

      a {
        color: $color-blue;
      }

      .icon {
        background-color: $color-blue;
        color: $color-blue-light;
      }

      .leaf {
        border-top-color: $color-blue;
      }
    }
  }

  .tb-menu-faire {
    .tb-megamenu-row > div:first-of-type,
    .tb-menu-links {
      background-color: $color-green;
    }

    .tb-menu-guichet-virtuel {
      background-color: $color-green-light;

      a {
        color: $color-green;
      }

      .icon {
        background-color: $color-green;
        color: $color-green-light;
      }

      .leaf {
        border-top-color: $color-green;
      }
    }
  }

  .tb-menu-commune {
    .tb-megamenu-row > div:first-of-type,
    .tb-menu-links {
      background-color: $color-red;
    }

    .tb-menu-guichet-virtuel {
      background-color: $color-red-light;

      a {
        color: $color-red;
      }

      .icon {
        background-color: $color-red;
        color: $color-red-light;
      }

      .leaf {
        border-top-color: $color-red;
      }
    }
  }

  .tb-menu-participer {
    .tb-megamenu-row > div:first-of-type,
    .tb-menu-links {
      background-color: $color-brown;
    }

    .tb-menu-guichet-virtuel {
      background-color: $color-brown-light;

      a {
        color: $color-brown;
      }

      .icon {
        background-color: $color-brown;
        color: $color-brown-light;
      }

      .leaf {
        border-top-color: $color-brown;
      }
    }
  }

  .dropdown {
    position: static;
  }

  .dropdown-toggle {
    &:after {
      display: none;
    }
  }

  .dropdown-menu {
    top: auto;

    &, a {
      color: $color-white;
    }
  }
}

@include media-breakpoint-up(xl) {
  .tb-megamenu-item {
    &, a {
      font-size: pxToRem(16px);
    }

    &.level-1 {
      > .dropdown-menu {
        margin: 17px 0 0 -285px;
        max-width: 1140px;
      }
    }

    &.level-2 {
      width: 275px;
    }
  }
}
