.node-type-page {
  .node__full {
    .field-name-field-introduction {
      font-weight: 600;
      margin-top: 1rem;
    }
  }

  /**
  Map
   */
  .view-id-pages__gmap {
    border: 1px solid $color-gray;
    border-top: none;
  }

  /**
  Children pages grid
   */
  .view-id-pages_enfants_menu_je_suis_,
  .view-id-pages_enfants_menu_j_aimerais_,
  .view-id-pages_children {
    .view-content {
      @include media-breakpoint-up(md) {
        display: flex;
        flex-wrap: wrap;
        margin: -15px;
      }
    }

    .view-items {
      background-color: $color-blue-light;
      margin-top: 30px;

      &:first-of-type {
        margin-top: 0;
      }

      @include media-breakpoint-up(md) {
        margin: 15px;
        width: 330px;

        &:first-of-type {
          margin-top: 15px;
        }
      }

      @include media-breakpoint-up(xl) {
        width: 255px;
      }
    }

    .views-field-title {
      padding: 15px 15px 0;

      h2 {
        font-family: $font-family-sans-serif;
        font-size: 1.3rem;
        font-weight: $fw-semi-bold;
        text-transform: uppercase;
      }
    }

    .views-field-field-introduction {
      padding: 0 15px 15px;

      @include media-breakpoint-up(lg) {
        margin-bottom: 8px;
        padding-bottom: 0;
      }

      p:last-of-type {
        margin-bottom: 0;
      }
    }

    .views-field-view-node {
      display: none;

      @include media-breakpoint-up(lg) {
        display: block;
        padding: 0 15px 15px;
      }
    }
  }
}

.node-type-page {
  &.vivre-a-plan-les-ouates .view-id-pages_children .view-items {
    background-color: $color-blue-light;
  }
  &.que-faire-a-plan-les-ouates .view-id-pages_children .view-items {
    background-color: $color-green-light;
  }
  &.commune .view-id-pages_children .view-items,
  &.la-commune .view-id-pages_children .view-items {
    background-color: $color-red-light;
  }
  &.participer .view-id-pages_children .view-items {
    background-color: $color-brown-light;
  }

  .view-id-pages_enfants_menu_j_aimerais_ .view-items,
  .view-id-pages_enfants_menu_je_suis_ .view-items {
    background-color: $color-yellow;

    a {
      color: $color-turquoise;
    }
  }
}

.node-type-page-inscriptions {
  .field-item {
    font-weight: 300;
    margin: 44px 20px;

    a {
      white-space: nowrap;
      background-color: $color-turquoise;
      color: $color-white;
      font-family: $font-family-sans-serif;
      font-size: 1.125rem;
      font-weight: $fw-regular;
      padding: 15px;
      margin: 0;
      text-align: center;
      text-transform: uppercase;

      @media (max-width: 783px) {
        width: 100%;
        display: block;
      }
    }
  }

  .group-de-liens {
    display: block;
    margin: 0 auto 30px;
    width: max-content;

    @media (max-width: 783px) {
      display: block;
      margin: 0 auto;
      width: 100%;
    }
  }

  .fieldset-wrapper {
    display: flex;
    width: 100%;

    @media (max-width: 783px) {
      width: 100%;
      display: block;
    }
  }

  .field-name-field-intro .field-item {
    text-align: center;
    font-size: 2rem;
    font-weight: 500;
    margin-bottom: 20px;
  }
}

.page-user-password .tabs.primary, .page-user-signup .tabs.primary {
  display: none;
}

.page-user-login .tabs.primary li:not(:last-child) {
  display: none;
}
