.front {
  #header_image {
    @include media-breakpoint-up(md) {
      height: 500px;
    }
  }

  #main {
    padding-bottom: 0;
  }

  .toggle-search {
    @include media-breakpoint-up(lg) {
      display: none;
    }
  }

  .col-lg-12 {
    @include media-breakpoint-down(sm) {
      display: flex;
      flex-direction: column;
    }
  }

  .block {
    h2 {
      position: relative;
      margin-bottom: 25px;
      text-align: center;

      &:after {
        border-bottom: 1px solid $color-turquoise;
        border-top: 1px solid $color-turquoise;
        bottom: -10px;
        content: '';
        display: block;
        height: 5px;
        left: 50%;
        position: absolute;
        transform: translateX(-50%);
        width: 120px;
      }
    }

    .view-all {
      color: $color-black;
      border: 4px double $color-turquoise;
      display: block;
      font-weight: $fw-semi-bold;
      padding: 8px 15px;
      margin: 30px auto;
      max-width: 180px;
      text-align: center;
      text-transform: uppercase;

      @include media-breakpoint-up(sm) {
        max-width: 250px;
      }

      &:hover {
        text-decoration: none;
      }
    }
  }

  .block-portal {
    margin-bottom: 30px;

    @include media-breakpoint-down(sm) {
      margin: -170px -15px 5px;
      order: 1;
    }

    .block-w {
      height: 95px;
      position: relative;

      @include media-breakpoint-up(md) {
        height: 150px;
      }
    }

    .menu-block-wrapper {
      background-color: $color-yellow;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      padding: 0 45px;

      @include media-breakpoint-up(md) {
        padding: 30px 45px;
      }

    }

    .menu-name-menu-je-suis {
      z-index: 1;
    }
    .menu-name-menu-j-aimerais {
      z-index: -1;
    }

    .portal-togglers {
      display: flex;

      a {
        background-color: $color-black;
        color: $color-white;
        display: block;
        flex-shrink: 1;
        flex-grow: 1;
        flex-basis: 0;
        padding: 15px;
        text-align: center;
        text-decoration: none;

        @include media-breakpoint-down(sm) {
          padding: 5px 15px;
        }

        &.active {
          background-color: $color-turquoise;
          cursor: default;
          pointer-events: none;
          position: relative;
          z-index: 2;

          &:after {
            border-top: 10px solid $color-turquoise;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            bottom: -10px;
            content: "";
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
          }
        }
      }
    }

    .menu {
      a {
        align-items: center;
        color: inherit;
        display: flex;
        flex-direction: column;
        height: 95px;
        line-height: 1.2;
        padding: 0;
        text-align: center;
        text-transform: uppercase;

        @include media-breakpoint-down(sm) {
          font-size: pxToRem(14px);
          padding: 15px 0;
        }

        &:hover {
          text-decoration: none;
        }
      }

      .icon {
        font-size: 50px;
        margin-bottom: auto;

        @include media-breakpoint-down(sm) {
          font-size: 40px;
        }
      }
    }
  }

  .block-quick-access {
    background-color: $color-white;
    margin: 0 -15px;
    padding: 30px 15px;

    @include media-breakpoint-down(sm) {
      order: 2;
    }

    @include media-breakpoint-up(md) {
      padding-top: 0;
    }

    h2 {
      @include media-breakpoint-up(md) {
        display: none;
      }
    }

    li {
      color: $color-white;
      background-color: $color-turquoise;
      margin-top: 1px;
      text-transform: uppercase;
      width: 100%;

      @include media-breakpoint-up(md) {
        background-color: $color-black;
        margin: 0 8px;

        &:first-of-type {
          margin-left: 0;
        }

        &:last-of-type {
          margin-right: 0;
        }
      }
    }

    a {
      align-items: center;
      color: inherit;
      display: flex;
      padding: 10px;

      &:hover {
        text-decoration: none;
      }

      @include media-breakpoint-up(md) {
        flex-direction: column;
        font-size: pxToRem(14px);
        height: 125px;
        line-height: 1.2;
        padding: 15px;
        position: relative;
        text-align: center;
      }

      @include media-breakpoint-up(lg) {
        font-size: pxToRem(16px);
      }

      @include media-breakpoint-up(xl) {
        font-size: pxToRem(18px);
      }

      @media screen and (min-width: 992px) and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        font-size: pxToRem(13px);
      }

      @media screen and (min-width: 1200px) and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        font-size: pxToRem(13px);
      }
    }

    .menu-block-wrapper {
      position: relative;

      @include media-breakpoint-up(md) {
        height: 125px;
        margin-bottom: -75px;
      }
    }

    .menu {
      flex-shrink: 1;
      flex-grow: 1;
      flex-basis: 0;

      @include media-breakpoint-up(md) {
        display: flex;
        margin: 0 auto;
        max-width: 690px;
        position: absolute;
        left: 50%;
        top: -75px;
        width: 100%;
        transform: translateX(-50%);
      }

      @include media-breakpoint-up(lg) {
        max-width: 840px;
      }
    }

    .icon {
      font-size: 36px;
      margin-right: 10px;

      @include media-breakpoint-up(md) {
        color: $color-white;
        font-size: 50px;
        margin-bottom: auto;
        margin-right: 0;
      }
    }
  }

  .block-latest-news {
    background-color: $color-yellow;
    margin: 0 -15px;

    padding: 30px 15px 1px;

    @include media-breakpoint-down(sm) {
      order: 4;
    }

    .view-content {
      @include media-breakpoint-up(md) {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
      }
    }

    .view-items {
      margin-bottom: 15px;

      @include media-breakpoint-up(md) {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        width: 338px;
      }

      @include media-breakpoint-up(lg) {
        width: 221px;
      }

      @include media-breakpoint-up(xl) {
        width: 266px;
      }

      &:nth-of-type(odd) {
        @include media-breakpoint-down(sm) {
          .article-meta {
            background-color: $color-black;

            .field-category {
              color: $color-turquoise;
            }
          }
        }
      }

      &:nth-of-type(even) {
        @include media-breakpoint-down(sm) {
          .article-meta {
            background-color: $color-turquoise;

            .field-category {
              color: $color-black;
            }
          }
        }
      }

      &:first-of-type,
      &:nth-of-type(2),
      &:nth-of-type(5),
      &:nth-of-type(6) {
        @include media-breakpoint-up(md) {
          .article-meta {
            background-color: $color-black;

            .field-category {
              color: $color-turquoise;
            }
          }
        }
      }

      &:nth-of-type(3),
      &:nth-of-type(4) {
        @include media-breakpoint-up(md) {
          .article-meta {
            background-color: $color-turquoise;

            .field-category {
              color: $color-black;
            }
          }
        }
      }

      &:first-of-type,
      &:last-of-type {
        @include media-breakpoint-up(md) {
          width: 690px;

          .article-header {
            display: flex;
          }

          .article-meta {
            width: 337px;
          }

          .article-image {
            display: block;
            width: 353px;
          }
        }

        @include media-breakpoint-up(lg) {
          width: 457px;

          .article-meta {
            width: 221px;
          }

          .article-image {
            width: 236px;
          }
        }

        @include media-breakpoint-up(xl) {
          width: 547px;

          .article-meta {
            width: 266px;
          }

          .article-image {
            width: 281px;
          }
        }
      }

      &:last-of-type {
        .article-header {
          @include media-breakpoint-up(md) {
            flex-direction: row-reverse;
          }
        }
      }

      &:nth-of-type(n + 4) {
        display: none;

        @include media-breakpoint-up(md) {
          display: flex;
        }
      }
    }

    .article-header {
      @include media-breakpoint-up(md) {
        height: 300px;
      }

      @include media-breakpoint-up(lg) {
        height: 250px;
      }
    }

    .article-meta {
      color: $color-white;
      display: flex;
      flex-direction: column;
      min-height: 230px;
      position: relative;
      text-transform: uppercase;

      h3 {
        font-family: $font-family-sans-serif;
        font-size: pxToRem(24px);
        min-height: inherit;
        margin: 0;
      }

      .views-field-created {
        font-size: 12px;
        padding: 15px;
        position: absolute;
        right: 0;
        top: 0;
      }

      .views-field-title {
        min-height: inherit;
      }

      .field-path {
        color: inherit;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        min-height: inherit;
        padding: 15px;
        text-decoration: none;

        @include media-breakpoint-up(md) {
          height: 300px;
        }

        @include media-breakpoint-up(lg) {
          height: 250px;
        }
      }

      .field-category {
        color: $color-turquoise;
        display: block;
        font-size: pxToRem(16px);

        @include media-breakpoint-only(lg) {
          font-size: pxToRem(14px);
        }
      }

      .field-title {
        display: block;

        @include media-breakpoint-only(lg) {
          font-size: pxToRem(21px);
        }
      }
    }

    .article-image {
      display: none;
    }

    .article-content {
      background-color: $color-white;
      hyphens: auto;
      padding: 15px;

      @include media-breakpoint-up(md) {
        display: flex;
        flex: 1;
        flex-direction: column;
        width: 100%;
      }

      @media screen and (min-width: 768px) and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
        flex-shrink: 1;
        flex-grow: 1;
        flex-basis: auto;
      }

      @include media-breakpoint-only(lg) {
        font-size: 14px;
      }

      p {
        margin: 0;
      }

      .views-field-view-node {
        display: none;

        @include media-breakpoint-up(md) {
          display: block;
          margin-top: auto;
          padding-top: 5px;
        }
      }
    }
  }

  .block-agenda {
    @include media-breakpoint-down(sm) {
      order: 3;
    }

    .view-content {
      &.slick-slider {
        .slick-slide {
          margin: 0 30px 0 0;

          > div {
            display: flex;
            padding-bottom: 15px;
          }

          .view-items {
            padding-bottom: 15px;
            margin: 30px 15px 15px 30px;
          }

          .article-content {
            display: flex;
            flex-direction: column;
            height: 100%;
            padding-bottom: 15px;
          }

          .views-field-title {
            margin-top: auto;
          }
        }

        .slick-prev {
          left: -12px;
        }

        .slick-next {
          right: -12px;
        }

        .slick-prev, .slick-next {
          font-size: 20px;
          height: 20px;
          width: 20px;

          &:before {
            font-size: inherit;
          }
        }
      }
    }
    .view-items {
      background-color: $color-black;
      padding: 15px;
      position: relative;
      margin: 30px 0 15px 15px;

      &,
      a {
        color: $color-white;
        text-decoration: none;
      }

      h3 {
        font-family: $font-family-sans-serif;
        font-size: pxToRem(20px);
        margin-top: 15px;
        text-transform: uppercase;

        @include media-breakpoint-only(lg) {
          //font-size: pxToRem(21px);
        }
      }

      p {
        font-size: pxToRem(14px);
        margin: 0;
      }
    }

    .views-field-field-event-date {
      @extend %date-block;

      left: -15px;
      top: -15px
    }

    .views-field-field-article-categories {
      font-size: pxToRem(13px);
      padding: 0 0 15px 55px;
      text-transform: uppercase;

      .field-content {
        position: relative;

        &:after {
          content: '';
          border-bottom: 1px solid $color-turquoise;
          position: absolute;
          height: 1px;
          width: 100%;
          bottom: -5px;
          left: 0;
        }
      }
    }
  }

  .region-footer-before-first {
    display: none;
  }
}
