%date-block {
  background-color: $color-turquoise;
  left: 0;
  padding: 2px;
  position: absolute;
  text-align: center;
  top: 0;
  min-width: 70px;

  @include media-breakpoint-only(md) {
    min-width: 63px;
  }

  .d, .m, .y {
    display: block;
    font-style: normal;
  }

  .d {
    color: $color-white;
    font-size: pxToRem(35px);
    font-weight: $fw-extra-bold;
    line-height: .9;

    @include media-breakpoint-only(md) {
      font-size: pxToRem(30px);
    }
  }

  .m {
    color: $color-black;
    font-size: pxToRem(23px);
    font-weight: $fw-extra-bold;
    line-height: .8;
    text-transform: uppercase;

    @include media-breakpoint-only(md) {
      font-size: pxToRem(20px);
    }
  }

  .y {
    color: $color-black;
    font-size: pxToRem(16px);
    font-weight: $fw-light;
    line-height: 1.1;

    @include media-breakpoint-only(md) {
      font-size: pxToRem(14px);
    }
  }
}

.page-agenda {
  .agenda-header {
    display: none;
  }
}

.view-id-agenda {
  @extend .view-id-articles;

  .views-fieldset {
    &.article-image {
      position: relative;

      @include media-breakpoint-up(xl) {
        flex-shrink: 1;
        flex-grow: 1;
        flex-basis: 0;
      }
    }

    &.article-content {
      background-color: $color-black;
      color: $color-white;
      margin-top: 0;
      padding: 15px;

      @include media-breakpoint-only(md) {
        flex-grow: 2;
        flex-shrink: 1;
        flex-basis: 0;
      }

      @include media-breakpoint-up(xl) {
        flex-grow: 2;
        flex-shrink: 1;
        flex-basis: 0;
      }
    }

    &.article-informations {
      background-color: $color-yellow;

      @include media-breakpoint-only(md) {
        flex-grow: 2;
        flex-shrink: 1;
        flex-basis: 0;
      }

      @include media-breakpoint-up(md) {
        margin-left: 15px;
      }

      @include media-breakpoint-up(xl) {
        flex-shrink: 1;
        flex-grow: 1;
        flex-basis: 0;
      }

      .views-field-field-event-date-2 {
        .date-display-single {
          display: none;
        }
      }
    }
  }

  .views-field-field-event-date {
    @extend %date-block;
  }

  .views-field-field-article-categories {
    a {
      color: $color-white;
    }
  }

  .views-field-title {
    h2 {
      @include media-breakpoint-only(md) {
        font-size: 1.3rem;
      }
    }
    a {
      color: $color-white;
    }
  }

  .views-field-field-introduction {
    display: none;

    @include media-breakpoint-up(xl) {
      display: block;
      font-size: pxToRem(15px);
      font-weight: $fw-light;
    }
  }

  .views-field-view-node {
    @include media-breakpoint-up(md) {
      display: none;
      a {
        background-color: transparent;
        color: $color-turquoise;
        font-size: pxToRem(15px);
        font-weight: $fw-semi-bold;
        margin-top: auto;
        padding: 0;
        text-transform: uppercase;

        &:hover, &:active, &:focus {
          background-color: transparent;
        }
      }
    }

    @include media-breakpoint-up(xl) {
      display: block;
    }
  }

  .views-field-field-event-date-2 {
    display: none;

    @include media-breakpoint-up(md) {
      display: block;
    }
  }

  .views-field-field-event-end-time {
    padding: 10px 10px 5px;

    @include media-breakpoint-up(md) {
      padding-top: 5px;
    }

    @include media-breakpoint-up(xl) {
      padding: 20px 15px 10px;
    }
  }

  .views-field-field-price-info {
    padding: 5px 10px 10px;

    @include media-breakpoint-up(md) {
      padding-bottom: 5px;
    }

    @include media-breakpoint-up(xl) {
      padding: 10px 15px 10px;
    }
  }

  .views-field-field-event-target-audienc {
    display: none;

    @include media-breakpoint-up(md) {
      display: block;
      padding: 5px 10px 10px;
    }

    @include media-breakpoint-up(xl) {
      padding: 10px 15px 20px;
    }

  }

  .event-info-group {
    display: flex;
    align-items: center;

    [class^="icon-"] {
      font-size: 30px;
      margin-right: 5px;

      @include media-breakpoint-up(md) {
        margin-right: 10px;
      }
    }
  }

  .event-info-content {
    @include media-breakpoint-up(md) {
      font-size: pxToRem(14px);
    }
  }
}
