.node-type-article {
  #block-views-0d5aadce427542575d585b005bc74128 {
    display: none;

    @include media-breakpoint-up(lg) {
      display: block;
    }

    .view-items {
      ~ .view-items {
        border-top: 4px double $color-turquoise;
        margin-top: 12px;
        padding-top: 10px;
      }
    }

    .views-field-field-article-categories {
      font-size: pxToRem(13px);
      text-transform: uppercase;

      @include media-breakpoint-up(xl) {
        display: inline-block;
      }

      a {
        color: $color-turquoise;
      }
    }

    .views-field-created {
      font-size: pxToRem(13px);

      @include media-breakpoint-up(xl) {
        display: inline-block;

        &:before {
          content: '-';
        }
      }
    }

    .views-field-title {
      h3 {
        font-family: $font-family-sans-serif;
        font-size: pxToRem(16px);
        font-weight: $fw-medium;
        margin: 5px 0 0;
        text-transform: uppercase;

        @include media-breakpoint-up(lg) {
          margin: 0;
        }
      }
    }
  }

  .field-name-field-article-categories {
    font-size: pxToRem(14px);
    font-weight: $fw-semi-bold;
    text-transform: uppercase;

    @include media-breakpoint-up(lg) {
      display: inline-block;
      font-size: pxToRem(17px);
    }

    .field-item {
      display: inline-block;

      ~ .field-item {
        margin-left: 5px;

        &:before {
          color: $color-turquoise;
          content: "-";
          margin-right: 5px;
        }
      }
    }
  }

  .field-name-created_at {
    font-size: pxToRem(13px);
    font-weight: $fw-light;
    margin-bottom: pxToRem(8px);

    @include media-breakpoint-up(lg) {
      display: inline-block;
      font-size: pxToRem(17px);
      margin-bottom: 0;

      &:before {
        content: '-';
      }
    }
  }

  .field-name-field-introduction {
    font-weight: 600;
    margin-top: 1rem;
  }
}
