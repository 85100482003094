/**
IN page related pages
 */
#block-views-pages-related-related {
  display: none;

  @include media-breakpoint-up(lg) {
    display: block;
  }
}

.view-id-pages_related,
.view-id-related_articles,
.view-id-related_events {
  .view-header {
    border-top: 4px double $color-turquoise;
    margin-top: 20px;
    padding-top: 16px;

    h2 {
      font-size: pxToRem(28px);
      //margin: 0 0 25px 0;
      //padding: 15px 0;
      text-align: center;

      @include media-breakpoint-up(lg) {
        font-size: pxToRem(30px);
        text-align: left;
      }
    }
  }

  .node__content {
    background-color: $color-yellow;
    hyphens: auto;
    padding: 15px;

    h2 {
      font-family: $font-family-sans-serif;
      font-size: pxToRem(24px);
      font-weight: $fw-semi-bold;
      text-transform: uppercase;

      a {
        color: $color-black;
      }
    }

    a {
      color: $color-turquoise;
    }

    p {
      margin: 0;
    }
  }

  .views-field-field-article-categories {
    text-transform: uppercase;
  }

  .views-field-created {
    margin-bottom: 8px;
  }

  .views-field-view-node {
    display: none;

    @include media-breakpoint-up(lg) {
      display: block;
      margin-top: 8px;
    }
  }

  .slick-slider {
    margin: 0 30px 50px;

    @include media-breakpoint-up(xl) {
      margin: 0;
    }
  }

  .slick-list {
    @include media-breakpoint-up(md) {
      margin: 0 -15px;
    }
  }

  .slick-track {
    display: flex;
  }

  .slick-slide {
    background-color: $color-yellow;
    height: auto;

    @include media-breakpoint-up(md) {
      margin: 0 15px;
    }
  }

  .slick-dots {
    background-color: $color-white;
    bottom: -49px;
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 15px 0;
  }
}

#block-views-pages-related-related .view-id-pages_related,
#mobile__related-pages {
  .view-header {
    &,
    .vivre-a-plan-les-ouates & {
      border-top-color: $color-blue;
    }
    .que-faire-a-plan-les-ouates & {
      border-top-color: $color-green;
    }
    .commune &,
    .la-commune &,
    .pv-du-conseil-municipal & {
      border-top-color: $color-red;
    }
    .participer & {
      border-top-color: $color-brown;
    }
  }
  .node__content {
    &,
    .vivre-a-plan-les-ouates & {
      background-color: $color-blue-light;
    }
    .que-faire-a-plan-les-ouates & {
      background-color: $color-green-light;
    }
    .commune &,
    .la-commune &,
    .pv-du-conseil-municipal & {
      background-color: $color-red-light;
    }
    .participer & {
      background-color: $color-brown-light;
    }

    h2 {
      a {
        &,
        .vivre-a-plan-les-ouates & {
          color: $color-blue;
        }
        .que-faire-a-plan-les-ouates & {
          color: $color-green;
        }
        .commune &,
        .la-commune &,
        .pv-du-conseil-municipal & {
          color: $color-red;
        }
        .participer & {
          color: $color-brown;
        }
      }
    }

    a {
      &,
      .vivre-a-plan-les-ouates & {
        color: $color-blue;
      }
      .que-faire-a-plan-les-ouates & {
        color: $color-green;
      }
      .commune &,
      .la-commune &,
      .pv-du-conseil-municipal & {
        color: $color-red;
      }
      .participer & {
        color: $color-brown;
      }
    }
  }

  .slick-slide {
    &,
    .vivre-a-plan-les-ouates & {
      background-color: $color-blue-light;
    }
    .que-faire-a-plan-les-ouates & {
      background-color: $color-green-light;
    }
    .commune &,
    .la-commune &,
    .pv-du-conseil-municipal & {
      background-color: $color-red-light;
    }
    .participer & {
      background-color: $color-brown-light;
    }
  }

  .slick-dots {
    button:before {
      &,
      .vivre-a-plan-les-ouates & {
        color: $color-blue-light;
      }
      .que-faire-a-plan-les-ouates & {
        color: $color-green-light;
      }
      .commune &,
      .la-commune &,
      .pv-du-conseil-municipal & {
        color: $color-red-light;
      }
      .participer & {
        color: $color-brown-light;
      }
    }

    .slick-active {
      button:before {
        &,
        .vivre-a-plan-les-ouates & {
          color: $color-blue;
        }
        .que-faire-a-plan-les-ouates & {
          color: $color-green;
        }
        .commune &,
        .la-commune &,
        .pv-du-conseil-municipal & {
          color: $color-red;
        }
        .participer & {
          color: $color-brown;
        }
      }
    }
  }
}

#mobile__related-pages {
  .node__content {
    li {
      padding: 0;
    }
  }

  .links {
    display: none !important;
  }
}

#block-views-pages-related-related .view-id-pages_related {
  .content {
    margin-bottom: 8px;
  }

  .links {
    margin: auto 0 0;

    &.inline li {
      padding: 0;
    }
  }

  .has-children & {
    .view-header {
      h2 {
        text-align: center;
      }
    }
  }

  .no-children & {
    .view-items {
      margin: 0;

      ~ .view-items {
        border-top: 5px double $color-blue-light;
        margin-top: 15px;
        padding-top: 15px;
      }
    }

    .node-teaser {
      display: flex;
    }

    .node__image {
      flex-shrink: 1;
      flex-grow: 1;
      flex-basis: 0;
      margin-right: 15px;
    }

    .node__content {
      background-color: transparent;
      display: flex;
      flex-shrink: 1;
      flex-grow: 2;
      flex-basis: 0;
      flex-direction: column;
      font-size: pxToRem(13px);
      margin-left: 15px;
      padding: 0;

      h2 {
        font-size: pxToRem(24px);
      }
    }
  }
}

.view-id-related_events {
  .node__image {
    position: relative;
  }

  .node__content {
    background-color: $color-black;
    color: $color-white;

    h2 {
      a{
        color: $color-white;
      }
    }
  }
  .views-field-field-event-date {
    @extend %date-block;

    top: -15px
  }

  .slick-slide {
    background-color: $color-black;
    margin-top: 15px;
  }
}

.vivre-a-plan-les-ouates.no-children #block-views-pages-related-related .view-id-pages_related .view-items ~ .view-items {
  border-top-color: $color-blue-light;
}

.que-faire-a-plan-les-ouates.no-children #block-views-pages-related-related .view-id-pages_related .view-items ~ .view-items {
  border-top-color: $color-green-light;
}

.commune.no-children #block-views-pages-related-related .view-id-pages_related .view-items ~ .view-items,
.la-commune.no-children #block-views-pages-related-related .view-id-pages_related .view-items ~ .view-items {
  border-top-color: $color-red-light;
}

.participer.no-children #block-views-pages-related-related .view-id-pages_related .view-items ~ .view-items {
  border-top-color: $color-brown-light;
}
