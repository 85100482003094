.view-id-articles {
  .view-items {
    @include media-breakpoint-up(md) {
      display: flex;
    }

    ~ .view-items {
      border-top: 5px double lighten($color-turquoise, 25%);
      margin-top: 19px;
      padding-top: 24px;
    }
  }

  .views-fieldset {
    flex-shrink: 1;
    flex-grow: 1;
    flex-basis: 0;

    &.article-image {
      @include media-breakpoint-up(md) {
        margin-right: 15px;
      }
    }

    &.article-content {
      margin-top: 12px;

      @include media-breakpoint-up(md) {
        display: flex;
        flex-direction: column;
        margin-top: 0;
      }
    }
  }

  .views-field-field-article-categories {
    font-size: pxToRem(14px);
    text-transform: uppercase;

    &, a {
      color: $color-turquoise;
    }
  }

  .views-field-created {
    font-size: pxToRem(14px);
  }

  .views-field-title {
    h2 {
      font-family: $font-family-sans-serif;
      font-size: 1.3rem;
      font-weight: $fw-semi-bold;
      margin: .3em 0;
      text-transform: uppercase;
    }

    a {
      color: $color-black;
    }
  }

  .views-field-field-introduction {
    line-height: 1.4;
    margin-bottom: pxToRem(12px);

    p:last-of-type {
      margin-bottom: 0;
    }
  }

  .views-field-view-node {
    display: none;

    a {
      @extend .btn;
      background-color: $color-black;
      color: $color-white;

      &:focus, &:hover {
        background-color: lighten($color-black, 15%);
      }
    }

    @include media-breakpoint-up(md) {
      display: block;
      margin-top: auto;
    }
  }
}
