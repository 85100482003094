.page-proces-verbaux-du-conseil-municipal {
  a {
    word-wrap: break-word;
  }

  p {
    margin: 0;

    ~ p {
      margin-top: .5em;
    }
  }

  .views-field-field-documents-1 {
    font-size: .9em;
    padding-left: 15px;
  }

  .view-id-pv .view-content {
    li {
      list-style: none;
      margin-bottom: 0;
      margin-top: 1em;
      position: relative;

      &:before {
        color: $color-red;
        content: '\e90b';
        font-family: 'icomoon';
        font-size: 12px;
        left: -18px;
        position: absolute;
        top: 2px;
      }
    }
  }

  .file img {
    display: none;
  }
}
