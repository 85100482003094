.page-user {
  input[type=text],
  input[type=tel],
  input[type=password],
  textarea {
    background: $color-turquoise-light none;
    border: none;
    color: $color-black;
    font-size: 0.875rem;
    font-weight: $fw-light;
    margin: 0;
    max-width: 500px;
    padding: 8px 10px;
    width: 100%;
  }

  input[type=submit] {
    background: $color-black none;
    border: none;
    color: $color-white;
    cursor: pointer;
    display: block;
    font-size: 1rem;
    font-weight: $fw-light;
    padding: 8px 30px;
  }

  .form-textarea-wrapper {
    max-width: 500px;
  }
}

.page-compte-utilisateur {
  .tabs.primary li:nth-of-type(-n+3) {
    display: none;
  }
}
