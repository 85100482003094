img {
  height: auto;
  max-width: 100%;
}

h1, h2, h3, h4, h5, h6 {
  hyphens: auto;
}

a {

    text-decoration: none;

  .vivre-a-plan-les-ouates & {
    color: $color-blue;
  }
  .que-faire-a-plan-les-ouates & {
    color: $color-green;
  }
  .commune &,
  .la-commune &,
  .pv-du-conseil-municipal & {
    color: $color-red;
  }
  .participer & {
    color: $color-brown;
  }
}

p {
  hyphens: auto;

  ~ p {
    margin-top: 1rem;
  }
}

#page-wrapper {
  //overflow-x: hidden;
}

#main-wrapper {
  margin-top: 200px;

  @include media-breakpoint-up(sm) {
    margin-top: 280px;
  }

  @include media-breakpoint-up(md) {
    margin-top: 130px;
  }

  .front & {
    margin-top: 342px;

    @include media-breakpoint-up(md) {
      margin-top: 207px;
    }
  }
}

#main {
  background-color: $color-white;
  padding: 15px;

  //@include media-breakpoint-up(sm) {
  //  margin-top: -51px;
  //  position: relative;
  //}
  //
  //@include media-breakpoint-up(md) {
  //  margin-top: -95px;
  //}
  //
  //@include media-breakpoint-up(lg) {
  //  margin-top: -81px;
  //}
}

.region-header {
  position: relative;
}

#block-pmbcom-blocks-header-image {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: -1;
}

#header_image {
  height: 321px;

  background: transparent no-repeat top center;
  background-size: cover;
}

/**
  Tools
   */
#tools {
  border-bottom: 1px solid $color-gray-lighter;
  border-top: 1px solid $color-gray-lighter;
  display: flex;
  justify-content: space-between;
  margin-bottom: pxToRem(16px);
  padding: 5px 0;

  .tools-items {
    font-size: 0;

    a {
      color: $color-black;
      display: inline-block;
      font-size: 16px;
      margin: 0 2px;
      padding: 3px 7px 5px;
      text-decoration: none;
      vertical-align: text-top;

      &:focus, &:hover {
        color: $color-white;
        text-decoration: none;

        &,
        .vivre-a-plan-les-ouates & {
          background-color: $color-blue;
        }
        .que-faire-a-plan-les-ouates & {
          background-color: $color-green;
        }
        .commune &,
        .la-commune &,
        .pv-du-conseil-municipal & {
          background-color: $color-red;
        }
        .participer & {
          background-color: $color-brown;
        }
      }

      &.unflag-action {
        font-size: 16px;
        color: $color-white;

        &,
        .vivre-a-plan-les-ouates & {
          background-color: $color-blue;
        }
        .que-faire-a-plan-les-ouates & {
          background-color: $color-green;
        }
        .commune &,
        .la-commune &,
        .pv-du-conseil-municipal & {
          background-color: $color-red;
        }
        .participer & {
          background-color: $color-brown;
        }
      }

      [class^="icon-"] {
        display: inline-block;
        vertical-align: middle;
      }
    }
  }

  .addthis_inline_share_toolbox {
    svg {
      fill: $color-black !important;
      height: 24px !important;
      width: 24px !important;
    }

    .at-style-responsive .at-share-btn {
      padding: 5px 8px 7px 4px;
    }

    .at-resp-share-element {
      .at-share-btn {
        margin-bottom: 0;
        transition: none;

        &:focus, &:hover {
          color: $color-white;
          transform: none;

          &,
          .vivre-a-plan-les-ouates & {
            background-color: $color-blue;
          }
          .que-faire-a-plan-les-ouates & {
            background-color: $color-green;
          }
          .commune &,
          .la-commune &,
          .pv-du-conseil-municipal & {
            background-color: $color-red;
          }
          .participer & {
            background-color: $color-brown;
          }

          svg {
            fill: $color-white !important;
          }
        }
      }
    }
  }
}

.title {
  font-size: pxToRem(33px);

  @include media-breakpoint-up(sm) {
    font-size: pxToRem(40px);
  }
}

/**
Last modifications
 */
.view-id-derni_re_modification {
  color: $color-gray;
  font-size: pxToRem(13px);
  margin: 15px 0;

  @include media-breakpoint-up(sm) {
    text-align: right;
  }
}

.region-content {
  table {
    display: inline-block;
    vertical-align: top;
    max-width: 100%;
    overflow-x: auto;
    border-collapse: collapse;
    border-spacing: 0;

    height: auto !important;
    width: auto !important;

    tr {
      height: auto !important;
      width: auto !important;
    }

    td, th {
      padding: 3px 6px !important;
      border: 1px solid $color-gray !important;
      font-family: $font-family-sans-serif !important;
      height: auto !important;
      width: auto !important;
    }
  }

  .field-name-body {
    h1, h2, h3, h4, h5, h6,
    .h1, .h2, .h3, .h4, .h5, .h6 {
      font-family: $font-family-sans-serif;
      font-weight: $fw-bold;
      margin-top: 40px;

      .vivre-a-plan-les-ouates & {
        color: $color-blue;
      }
      .que-faire-a-plan-les-ouates & {
        color: $color-green;
      }
      .commune &,
      .la-commune &,
      .pv-du-conseil-municipal & {
        color: $color-red;
      }
      .participer & {
        color: $color-brown;
      }
    }

    h1, h2,
    .h1, .h2 {
      font-size: 1.5rem;
    }

    h3,
    .h3 {
      font-size: 1.4rem;
    }

    h4,
    .h4 {
      font-size: 1.3rem;
    }

    h5,
    .h5 {
      font-size: 1.2rem;
    }

    h6,
    .h6 {
      font-size: 1.1rem;
    }
  }
}

.captcha-admin-links {
  display: none;

  .webform-client-form & {
    display: block !important;
  }
}

.collapse-text-fieldset {
  height: auto !important;

  .fieldset-legend {
    background: none !important;
    padding-left: 0 !important;

    span.summary {
      display: none;
    }
  }

  &.collapsed .fieldset-title:after {
    transform: rotate(0);
  }

  .fieldset-title {
    display: block;
    border-bottom: 1px solid $color-turquoise;
    background: none !important;

    .vivre-a-plan-les-ouates & {
      border-bottom-color: $color-blue;
    }
    .que-faire-a-plan-les-ouates & {
      border-bottom-color: $color-green;
    }
    .commune &,
    .la-commune &,
    .pv-du-conseil-municipal & {
      border-bottom-color: $color-red;
    }
    .participer & {
      border-bottom-color: $color-brown;
    }

    &:hover, &:active, &:focus {
      text-decoration: none;
    }

    &:after {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      color: $color-turquoise;
      content: "\E90E";
      font: 22px icomoon;
      padding: 9px;
      pointer-events: none;
      position: absolute;
      right: 0;
      top: 0;
      transform: rotate(180deg);

      .vivre-a-plan-les-ouates & {
        color: $color-blue;
      }
      .que-faire-a-plan-les-ouates & {
        color: $color-green;
      }
      .commune &,
      .la-commune &,
      .pv-du-conseil-municipal & {
        color: $color-red;
      }
      .participer & {
        color: $color-brown;
      }
    }
  }

  .fieldset-wrapper {
    padding: 10px 0;
  }
}
