#user-menu {
  background-color: $color-black;
  color: $color-white;

  a {
    color: inherit;

    &:hover {
      text-decoration: none;

      span.text {
        text-decoration: underline;
      }
    }
  }

  .nav-menu-toggler {
    background-color: $color-black;
    border: none;
    color: $color-white;
    height: 30px;
    padding: 1px 7px;
  }

  [class^="icon-"] {
    font-size: 1.4rem;
    padding: 0 5px;
    vertical-align: text-bottom;
  }

  @include media-breakpoint-up(sm) {
    text-align: right;

    a {
      font-size: pxToRem(16px);
      font-weight: $fw-light;
    }
  }
}

#nav-menu {
  background-color: $color-white;
  text-transform: uppercase;
  //}
}

#logo {
  align-items: center;
  color: $color-black;
  display: flex;
  justify-content: center;

  img {
    width: 64px;
  }

  .logo-text {
    margin-left: 10px;
    text-decoration: none;

    @include media-breakpoint-up(lg) {
      font-size: 13px;
    }

    @include media-breakpoint-up(xl) {
      font-size: 16px;
    }
  }
}

.js-size {
  &.disabled {
    pointer-events: none;
    cursor: default;

    &:hover {
      text-decoration: none;
    }
  }
}

.js-decrease-size {
  font-size: pxToRem(14px) !important;
}

.js-increase-size {
  font-size: pxToRem(18px) !important;
}
