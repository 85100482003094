/**
Resets
 */
ul.menu {
  padding-left: 0;
  margin: 0;
}

ul li.leaf, ul li.expanded {
  list-style: none;
  margin: 0;
}

li.expanded, li.collapsed, li.leaf {
  padding: 0;
}

li a.active {
  color: initial;
}


/**
Main menu
 */
.menu-block-2 {
  .menu {
    align-items: center;
    display: flex;
    justify-content: space-around;

    > li {
      display: inline-block;
      margin: pxToRem(14px) 0;

      > a {
        padding: pxToRem(5px);
      }

      > ul {
        display: none;
      }
    }

    a {
      font-size: 1rem !important;
    }
  }
}

#block-menu-block-1 {
  .content {
    padding: 0;
    font-size: pxToRem(14px);
  }

  .menu-block-1 {
    hyphens: auto;

    li {
      border-top: 1px solid $color-white;
    }

    a {
      display: block;
      padding: 15px;
    }

    .menu .menu {
      display: none;
    }

    .menu .open .menu {
      display: block;
    }

    /*
    First level
     */
    > .menu > li > a {
      font-weight: $fw-semi-bold;
      position: relative;
      padding-right: 35px;
      text-transform: uppercase;

      &.has-children:after {
        display: inline-block;
        width: 0;
        height: 0;
        margin-left: 0.255em;
        vertical-align: 0.255em;
        content: "";
        border-top: 0.3em solid;
        border-right: 0.3em solid transparent;
        border-bottom: 0;
        border-left: 0.3em solid transparent;
        position: absolute;
        right: 15px;
        top: 23px;
      }
    }

    > .menu > li.open > a {
      &.has-children:after {
        border-top: 0;
        border-right: 0.3em solid transparent;
        border-bottom: 0.3em solid;
        border-left: 0.3em solid transparent;
      }
    }

    /*
    Second level
     */
    > .menu > li > .menu > li {
      &,
      .vivre-a-plan-les-ouates & {
        background-color: $color-blue-lighter;
      }
      .que-faire-a-plan-les-ouates & {
        background-color: $color-green-lighter;
      }
      .commune &,
      .la-commune &,
      .pv-du-conseil-municipal & {
        background-color: $color-red-lighter;
      }
      .participer & {
        background-color: $color-brown-lighter;
      }
    }
  }
}

/**
Common
 */
#block-menu-block-1 .active-trail {
  > a {
    &,
    .vivre-a-plan-les-ouates & {
      color: $color-blue !important;
    }
    .que-faire-a-plan-les-ouates & {
      color: $color-green !important;
    }
    .commune &,
    .la-commune &,
    .pv-du-conseil-municipal & {
      color: $color-red !important;
    }
    .participer & {
      color: $color-brown !important;
    }
  }
}

/**
Accès rapide
 */
.region-footer-before-first {
  .block-menu-block {
    padding: 0 0 30px;

    h2 {
      text-align: center;

      @include media-breakpoint-down(md) {
        position: relative;
        margin-bottom: 25px;

        &:after {
          border-bottom: 1px solid $color-blue;
          border-top: 1px solid $color-blue;
          bottom: -10px;
          content: '';
          display: block;
          height: 5px;
          left: 50%;
          position: absolute;
          transform: translateX(-50%);
          width: 120px;

          .vivre-a-plan-les-ouates & {
            border-top-color: $color-blue;
            border-bottom-color: $color-blue;
          }
          .que-faire-a-plan-les-ouates & {
            border-top-color: $color-green;
            border-bottom-color: $color-green;
          }
          .commune &,
          .la-commune &,
          .pv-du-conseil-municipal & {
            border-top-color: $color-red;
            border-bottom-color: $color-red;
          }
          .participer & {
            border-top-color: $color-brown;
            border-bottom-color: $color-brown;
          }
        }
      }

      @include media-breakpoint-up(lg) {
        padding: 30px 0 0;
      }
    }

    li {
      color: $color-white;
      margin-top: 1px;
      text-transform: uppercase;
      width: 100%;

      &,
      .vivre-a-plan-les-ouates & {
        background-color: $color-blue;
      }
      .que-faire-a-plan-les-ouates & {
        background-color: $color-green;
      }
      .commune &,
      .la-commune &,
      .pv-du-conseil-municipal & {
        background-color: $color-red;
      }
      .participer & {
        background-color: $color-brown;
      }

      @include media-breakpoint-up(md) {
        margin-bottom: 1px;
        width: 49.8%;
      }

      @include media-breakpoint-up(lg) {
        margin-bottom: 0;
        width: 140px;

        &,
        .vivre-a-plan-les-ouates & {
          background-color: inherit;
        }
        .que-faire-a-plan-les-ouates & {
          background-color: inherit;
        }
        .commune &,
        .la-commune &,
        .pv-du-conseil-municipal & {
          background-color: inherit;
        }
        .participer & {
          background-color: inherit;
        }
      }
    }

    a {
      align-items: center;
      color: inherit;
      display: flex;
      padding: 10px;

      &:hover {
        text-decoration: none;
      }

      @include media-breakpoint-up(lg) {
        color: $color-black;
        flex-direction: column;
        line-height: 1.2;
        padding: 0;
        text-align: center;
      }
    }

    .menu {
      @include media-breakpoint-up(md) {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
      }
      @include media-breakpoint-up(lg) {
        justify-content: space-around;
      }
    }

    .icon {
      font-size: 36px;
      margin-right: 10px;

      @include media-breakpoint-up(lg) {
        font-size: 50px;
        margin-bottom: 15px;
        margin-right: 0;

        &,
        .vivre-a-plan-les-ouates & {
          color: $color-blue;
        }
        .que-faire-a-plan-les-ouates & {
          color: $color-green;
        }
        .commune &,
        .la-commune &,
        .pv-du-conseil-municipal & {
          color: $color-red;
        }
        .participer & {
          color: $color-brown;
        }
      }
    }
  }
}

/**
Guichet virtuel
 */
.region-content {
  .menu-name-menu-guichet-virtuel ul.menu {
    @include media-breakpoint-up(md) {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    li {
      background-color: $color-black;
      color: $color-white;
      margin: 1px 0 0 0;
      text-transform: uppercase;
      width: 100%;

      &.collapsed {
        list-style: none;
      }

      @include media-breakpoint-up(md) {
        margin-bottom: 1px;
        width: 49.8%;
      }

      @include media-breakpoint-up(lg) {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        margin-bottom: 15px;
        min-height: 179px;
        width: 31.9%;
      }
    }

    a {
      align-items: center;
      color: inherit;
      display: flex;
      padding: 10px;

      &:hover {
        text-decoration: none;
      }

      @include media-breakpoint-up(lg) {
        align-items: stretch;
        flex-direction: column;
        line-height: 1.2;
        padding: 0 0 15px;
        text-align: center;

        > * {
          margin-top: auto;
        }
      }
    }

    .icon {
      font-size: 36px;
      margin-right: 10px;

      @include media-breakpoint-up(lg) {
        background-color: $color-turquoise;
        font-size: 70px;
        margin-bottom: 15px;
        margin-right: 0;
        padding: 30px 0;
      }
    }
  }
}

.region-sidebar-first {
  #block-menu-block-5 {
    background-color: transparent;

    .content {
      padding: 0;
    }

    .menu-name-menu-guichet-virtuel ul.menu {
      font-size: pxToRem(15px);
      text-transform: uppercase;

      li {
        background-color: $color-yellow;
        margin-top: 1px;
      }

      a {
        display: block;
        padding: 10px 15px;

        &.active-trail {
          color: $color-turquoise;
        }
      }

      .icon {
        display: none;
      }
    }
  }
}
