.view-annonces {
  background-color: $color-turquoise;
  color: $color-white;

  ul {
    font-size: pxToRem(12px);
  }

  a {
    color: $color-white;
    text-decoration: underline;
  }
}
