.region-sidebar-first {
  @include media-breakpoint-only(md) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  /**
  Blocks
   */
  .block {
    background-color: $color-yellow;
    font-weight: $fw-light;
    margin: 15px 0;

    @include media-breakpoint-only(md) {
      width: 49%;
    }

    .vivre-a-plan-les-ouates & {
      background-color: $color-blue-light;
    }
    .que-faire-a-plan-les-ouates & {
      background-color: $color-green-light;
    }
    .commune &,
    .la-commune &,
    .pv-du-conseil-municipal & {
      background-color: $color-red-light;
    }
    .participer & {
      background-color: $color-brown-light;
    }

    h2 {
      background-color: $color-turquoise;
      color: $color-white;
      font-family: $font-family-sans-serif;
      font-size: pxToRem(18px);
      font-weight: 400;
      padding: 15px;
      margin: 0;
      text-align: center;
      text-transform: uppercase;

      .vivre-a-plan-les-ouates & {
        background-color: $color-blue;
      }
      .que-faire-a-plan-les-ouates & {
        background-color: $color-green;
      }
      .commune &,
      .la-commune &,
      .pv-du-conseil-municipal & {
        background-color: $color-red;
      }
      .participer & {
        background-color: $color-brown;
      }
    }

    a {
      color: $color-black;
    }

    ul {
      list-style: none;
      margin: 0;
    }

    li {
      margin: 0;
    }

    .content {
      font-weight: 300;
      padding: 10px 15px;
    }

    .views-fieldset {
      font-size: pxToRem(14px);

      legend {
        color: $color-turquoise;
        font-size: pxToRem(18px);
        font-weight: $fw-medium;
        margin-bottom: 2px;
      }

      ~ .views-fieldset {
        margin-top: 10px;
      }
    }

    /**
    Informations
     */
    .views-field-title {
      font-size: pxToRem(20px);
      font-family: $headings-font-family;
      line-height: 1.2;
      margin-bottom: .25rem;
    }

    .views-field-city {
      margin-bottom: .5rem;
    }

    .views-field-phone {
      font-weight: $fw-medium;
      margin-bottom: .5rem;
    }

    .views-field-email {
      margin-bottom: .5rem;
    }

    .views-field-field-websites {
      a {
        text-decoration: underline;
      }
    }

    .views-field-field-location-custom {
      .street-address {
        span {
          display: block;
        }
      }
    }

    /**
    Horaires
     */
    .views-field-field-office-hours-period {
      font-weight: $fw-medium;
      margin-bottom: .5rem;
      text-transform: uppercase;
    }

    .views-field-field-office-hours {
      margin-bottom: .25rem;

      .oh-display {
        display: block;

        ~ .oh-display {
          margin-top: .5rem;
        }
      }

      .oh-display-label {
        display: block;
        font-weight: $fw-medium;
      }

      .oh-display-times {
        font-size: .9em;
      }
    }

    /**
    Documents
     */
    .view-display-id-documents {
      li {
        ~ li {
          margin-top: .5rem;
        }
      }

      a {
        display: block;
        margin-left: 24px;
        position: relative;

        &:before {
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          content: "\e900";
          display: block;
          font-family: 'icomoon' !important;
          font-size: .8em;
          left: -24px;
          line-height: 1em;
          padding-top: .4em;
          position: absolute;

          &,
          .vivre-a-plan-les-ouates & {
            color: $color-blue;
          }
          .que-faire-a-plan-les-ouates & {
            color: $color-green;
          }
          .commune &,
          .la-commune &,
          .pv-du-conseil-municipal & {
            color: $color-red;
          }
          .participer & {
            color: $color-brown;
          }
        }
      }

      .file-icon {
        display: none;
      }
    }
  }

  /**
  Side menu
   */
  #block-user-login,
  #block-menu-block-1,
  #block-views-4c4eddd4d776840e5150ff6987ed931d {
    display: none;

    @include media-breakpoint-up(lg) {
      display: block;
    }
  }

  /**
  Document
   */
  #block-views-f940ec1e0af253a43cbdbd968f2ee4e4 {
    .field-content {
      display: flex;
      flex-direction: column;
      align-items: center;

      a {
        display: inline-block;

        &:hover {
          text-decoration: none;
        }
      }
    }

    .file-icon {
      color: $color-turquoise;
      font-size: pxToRem(100px);
      line-height: 1;
      padding: 10px 35px 10px 20px;
    }

    .file-dl {
      background: $color-turquoise none;
      border: none;
      color: $color-white;
      font-size: pxToRem(16px);
      font-weight: $fw-light;
      margin: 0 auto;
      padding: 8px 30px;
    }
  }

  /**
  Contact
   */
  #block-views-242aa60b2b99ba8d31e4d1ac0efa86a1 {
    .views-field-phone {
      font-weight: 400;
    }

    .guichet-info-group {
      display: flex;
      align-items: center;

      [class^="icon-"] {
        font-size: 26px;
        margin-right: 5px;

        @include media-breakpoint-up(md) {
          margin-right: 10px;
        }
      }
    }

    .guichet-info-content {
      @include media-breakpoint-up(md) {
        font-size: pxToRem(14px);
      }
    }
  }
}
