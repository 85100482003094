#block-search-form {
  padding-top: 70px;
  opacity: 0;
  position: absolute;
  transition: opacity .3s;
  pointer-events: none;
  width: 100%;

  .front & {
    @include media-breakpoint-up(md) {
      opacity: 1;
      pointer-events: auto;
    }
  }

  &.show-search {
    opacity: 1;
    pointer-events: auto;
  }

  @include media-breakpoint-up(md) {
    position: static;
    padding-top: 110px;
  }

  h2 {
    display: none;

    @include media-breakpoint-up(md) {
      .front & {
        display: block;
        margin-bottom: 15px;

        &:after {
          content: none;
        }
      }
    }
  }

  .container-inline {
    display: flex;
    align-items: center;
    justify-content: center;

    div {
      display: block;
    }
  }

  .form-item {
    margin: 0;
  }

  .form-actions {
    position: relative;

    &:after {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      background-color: $color-turquoise;
      color: $color-white;
      content: '\e909';
      font: 22px "icomoon";
      left: 0;
      padding: 9px;
      pointer-events: none;
      position: absolute;
      top: 0;
    }
  }

  .form-text {
    background: $color-white none;
    border: none;
    color: $color-black;
    font-size: pxToRem(16px);
    font-weight: $fw-light;
    margin: 0;
    padding: 8px 30px 8px 10px;
    width: 250px;

    @include media-breakpoint-up(sm) {
      width: 360px;
    }

    @include media-breakpoint-up(md) {
      width: 600px;
    }

    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      color: $color-black;
    }
    &::-moz-placeholder { /* Firefox 19+ */
      color: $color-black;
    }
    &:-ms-input-placeholder { /* IE 10+ */
      color: $color-black;
    }
    &:-moz-placeholder { /* Firefox 18- */
      color: $color-black;
    }
  }

  .form-submit {
    display: block;
    height: 40px;
    width: 40px;
    overflow: hidden;
    padding: 0;
  }
}

.block-top-searches {
  display: none;

  @include media-breakpoint-up(md) {
    display: block;
    margin-top: 15px;

    ul {
      display: flex;
      justify-content: center;
      list-style: none;
      margin: 0 auto;
      padding: 0;

      li {
        margin: 0 8px;
        padding: 0;

        a {
          background-color: $color-turquoise;
          color: $color-white;
          display: block;
          font-weight: $fw-light;
          padding: 8px 15px;
        }
      }
    }
  }
}

.page-search {
  .container-inline {
    display: flex;
    align-items: center;
    justify-content: center;

    div {
      display: block;
    }
  }

  .form-item {
    margin: 0;
    position: relative;

    &:after {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      background-color: $color-turquoise;
      color: $color-white;
      content: '\e909';
      font: 22px "icomoon";
      right: -40px;
      padding: 9px;
      pointer-events: none;
      position: absolute;
      top: 30px;
    }
  }

  .form-text {
    background: $color-white none;
    border: 1px solid $color-gray;
    border-right: none;
    color: $color-black;
    font-size: pxToRem(16px);
    font-weight: $fw-light;
    margin: 30px 0;
    padding: 7px 10px;
    width: 250px;

    @include media-breakpoint-up(sm) {
      width: 360px;
    }

    @include media-breakpoint-up(md) {
      width: 600px;
    }

    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      color: $color-black;
    }
    &::-moz-placeholder { /* Firefox 19+ */
      color: $color-black;
    }
    &:-ms-input-placeholder { /* IE 10+ */
      color: $color-black;
    }
    &:-moz-placeholder { /* Firefox 18- */
      color: $color-black;
    }
  }

  .form-submit {
    display: block;
    height: 40px;
    width: 40px;
    overflow: hidden;
    padding: 0;
  }

  .search-advanced {
    .form-item {
      &:after {
        display: none;
      }
    }

    .form-text {
      border-right: 1px solid $color-gray;
      margin: 0 0 30px;
      width: 100%;
    }

    .form-submit {
      background-color: $color-turquoise;
      border: none;
      color: $color-white;
      width: auto;
    }
  }

  .search-results {
    margin: 0 0 0.75em 0;
    padding: 0;
  }

  .search-result {
    padding: 0;
    list-style: none;
    margin: 1em 0 0 1.5em;
    position: relative;

    &:before {
      color: $color-turquoise;
      content: '\E90B';
      font: 13px 'icomoon';
      left: -18px;
      position: absolute;
      top: 5px;
    }

    a {
      font-family: $font-family-sans-serif;
    }
  }

  .search-snippet-info {
    padding: 0;
  }

  .search-snippet {
    margin-bottom: 1em;
  }

  .search-info {
    display: none;
  }
}
