.view-id-pages__gmap, .view-id-google_map_lieux {
  margin-bottom: 15px;
}

.view-google-map-carte-des-salles,
.view-google-map-carte-des-ecoles {
  margin-bottom: 30px;
}

#tools ~ .view .view-filters {
  display: none;
}

#gmap-auto1map-gmap0 > div > div > div:nth-child(1) > div:nth-child(3) > div:nth-child(2) > div:nth-child(4) > div {
//#gmap-auto1map-gmap0 > div > div > div:nth-child(1) > div:nth-child(4) > div:nth-child(4) > div {
  color: $color-white;

  //Border
  > div:nth-child(1) > div:nth-child(2) {
    background-color: $color-black !important;
    border-radius: 0 !important;
  }
  //Popup pointer
  > div:nth-child(1) > div:nth-child(3) > div > div {
    background-color: $color-black !important;
  }
  //Background
  > div:nth-child(1) > div:nth-child(4) {
    background-color: $color-black !important;
    border-radius: 0 !important;
  }

  //Close
  > div:nth-child(3) {
    opacity: 1;

    > img {
      filter: invert(80%);
    }
  }

  > div.gm-style-iw {
    left: 0 !important;
    top: 0 !important;

    .gmap-popup {

      a {
        color: $color-white;
      }

      .media {
        > a {
          display: none;

          @include media-breakpoint-up(sm) {
            display: block;

            img {
              width: 100px;
            }
          }
        }
        //img {
        //  width: 100px;
        //}
        //
        //@include media-breakpoint-up(sm) {
        //  display: i;
        //}
      }

      .media-body {
        margin-left: 15px;
        margin-top: 9px;
        max-width: 150px;
      }
    }
  }

  .poi-info-window {
    margin-left: 15px;
    margin-top: 9px;

    div, a {
      background-color: $color-black;
      color: $color-white !important;
    }
  }
}

.gmap-popup {
  .views-field-body {
    width: 250px;
  }
}
