@import "common/variables";

@import "~bootstrap/scss/bootstrap";
@import "~slick-carousel/slick/slick.scss";
@import "~slick-carousel/slick/slick-theme.scss";
@import "~slick-lightbox/src/styles/slick-lightbox.sass";

@import "common/mixins";
@import "common/global";
@import "common/fonts";

@import "components/header";
@import "components/menu";
@import "components/mega-menu";
@import "components/sections";
@import "components/sidebar";
@import "components/breadcrumbs";
@import "components/pager";
@import "components/sliders";
@import "components/related";
@import "components/forms";
@import "components/maps";
@import "components/addToCal";
@import "components/newsletter";
@import "components/footer";
@import "components/search";
@import "components/announcements";
@import "components/feeds";
@import "components/tabs";

@import "layouts/page";
@import "layouts/page-virtual";
@import "layouts/articles";
@import "layouts/events";
@import "layouts/article";
@import "layouts/event";
@import "layouts/location";
@import "layouts/taxonomy";
@import "layouts/home";

@import "pages/activites";
@import "pages/cff";
@import "pages/pv";
@import "pages/plan";
@import "pages/contact";
@import "pages/sitemap";
@import "pages/user";
