.region-footer-before-second #block-block-1 {
  background-color: $color-gray-dark;
  color: $color-white;
  margin: 0 -15px;
  padding: 15px 15px 30px;

  @include media-breakpoint-up(md) {
    padding: 15px;

    .front & {
      background-color: $color-black;
    }
    .vivre-a-plan-les-ouates & {
      background-color: $color-blue;
    }
    .que-faire-a-plan-les-ouates & {
      background-color: $color-green;
    }
    .commune &,
    .la-commune &,
    .pv-du-conseil-municipal & {
      background-color: $color-red;
    }
    .participer & {
      background-color: $color-brown;
    }
  }

  .inf-form {
    text-align: center;

    @include media-breakpoint-up(md) {
      border: 3px dashed $color-white;
      border-image: url("../images/dashed.png") 2 round;
      padding: 15px;
    }

    @include media-breakpoint-up(lg) {
      padding: 30px 15px;
    }

    h4 {
      font-size: pxToRem(32px);

      ~ span {
        display: block;
        font-size: pxToRem(14px);
        margin-bottom: 1rem;
        text-transform: uppercase;
      }
    }

    .inf-content {
      display: flex;
      justify-content: center;
    }

    .inf-input-text {
      position: relative;

      &:after {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        background-color: $color-turquoise;
        color: $color-white;
        content: '\e917';
        font: 22px "icomoon";
        right: -40px;
        padding: 9px;
        pointer-events: none;
        position: absolute;
        top: 0;

        @include media-breakpoint-up(md) {
          background-color: $color-white;
          color: $color-turquoise;

          .vivre-a-plan-les-ouates &,
          .que-faire-a-plan-les-ouates &,
          .commune &,
          .la-commune &,
          .pv-du-conseil-municipal &,
          .participer & {
            background-color: $color-white;
          }

          .vivre-a-plan-les-ouates & {
            color: $color-blue;
          }
          .que-faire-a-plan-les-ouates & {
            color: $color-green;
          }
          .commune &,
          .la-commune &,
          .pv-du-conseil-municipal & {
            color: $color-red;
          }
          .participer & {
            color: $color-brown;
          }
        }
      }

      input {
        background-color: $color-white;
        border: 1px solid transparent;
        color: $color-turquoise;
        font-weight: $fw-regular;
        padding: 7px 5px;

        @include media-breakpoint-up(md) {
          background-color: $color-turquoise;
          color: $color-white;
          width: 250px;

          .vivre-a-plan-les-ouates &,
          .que-faire-a-plan-les-ouates &,
          .commune &,
          .la-commune &,
          .pv-du-conseil-municipal &,
          .participer & {
            border-color: $color-white;
            color: $color-white;
          }

          .vivre-a-plan-les-ouates & {
            background-color: $color-blue;
          }
          .que-faire-a-plan-les-ouates & {
            background-color: $color-green;
          }
          .commune &,
          .la-commune &,
          .pv-du-conseil-municipal & {
            background-color: $color-red;
          }
          .participer & {
            background-color: $color-brown;
          }
        }

        @include media-breakpoint-up(lg) {
          width: 300px;
        }

        @include media-breakpoint-up(xl) {
          width: 350px;
        }

        &::-webkit-input-placeholder {
          color: $color-turquoise-light;
        }
        &::-moz-placeholder {
          color: $color-turquoise-light;
        }
        &:-ms-input-placeholder {
          color: $color-turquoise-light;
        }
        &:-moz-placeholder {
          color: $color-turquoise-light;
        }

        @include media-breakpoint-up(md) {
          &::-webkit-input-placeholder {
            color: $color-white;
          }
          &::-moz-placeholder {
            color: $color-white;
          }
          &:-ms-input-placeholder {
            color: $color-white;
          }
          &:-moz-placeholder {
            color: $color-white;
          }
        }
      }
    }

    .inf-submit input {
      border: none;
      height: 40px;
      width: 40px;
      overflow: hidden;
      padding: 0;
      //background-color: $color-white;
      //border: 1px solid $color-white;
    }
  }
}
