.page-contact {
  #main-wrapper {
    form {
      margin: 0 auto;
      max-width: 500px;
    }

    label {
      color: $color-turquoise;
    }

    input[type=radio],
    input[type=checkbox] {
      ~ label {
        color: inherit;
        font-size: pxToRem(14px);
      }
    }

    input[type=text], input[type=email], input[type=search],
    textarea {
      background: $color-turquoise-light none;
      border: none;
      color: $color-black;
      font-size: pxToRem(14px);
      font-weight: $fw-light;
      margin: 0;
      //max-width: 500px;
      padding: 8px 10px;
      width: 100%;
    }

    select {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      background: $color-turquoise-light none;
      border: none;
      border-radius: 0;
      color: $color-black;
      font-size: pxToRem(14px);
      font-weight: $fw-light;
      margin: 0 -37px 0 0;
      padding: 7px 47px 6px 10px;
      -ms-word-break: normal;
      word-break: normal;

      &::-ms-expand {
        display: none;
      }
    }

    .form-type-select {
      display: flex;
      flex-wrap: wrap;

      &:after {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        background-color: $color-turquoise;
        color: $color-white;
        content: '\e90e';
        cursor: pointer;
        font: 21px "icomoon";
        padding: 8px;
        pointer-events: none;
      }

      label {
        width: 100%;
      }
    }

    .form-textarea-wrapper {
      //max-width: 500px;
    }

    .form-actions {
      input {
        background: $color-black none;
        border: none;
        color: $color-white;
        cursor: pointer;
        display: block;
        font-size: pxToRem(16px);
        font-weight: $fw-light;
        padding: 8px 30px;
      }
    }
  }
}
