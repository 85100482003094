.block-pmbcom-social-feeds {
  background-color: $color-white;
  margin: 0 -15px;
  padding: 30px 15px;

  h2 {
    margin-bottom: 50px !important;
  }

  .feed {
    position: relative;

    //  &:before {
    //    position: absolute;
    //    top: 0;
    //    left: 50%;
    //    height: 30px;
    //    width: 30px;
    //    margin: -15px 0 0 -15px;
    //    background-color: $color-turquoise;
    //    color: $color-white;
    //    font-family: 'icomoon';
    //    font-size: 20px;
    //    -webkit-font-smoothing: antialiased;
    //    -moz-osx-font-smoothing: grayscale;
    //    line-height: 20px;
    //    padding: 5px;
    //  }
    //
    //&.facebook {
    //  &:before {
    //    content: '\e905';
    //  }
    //}
  }

  .feed-page-link {
    background-color: $color-turquoise;
    display: inline-block;
    color: $color-white;
    font-size: 20px;
    line-height: 20px;
    padding: 4px 5px;
    position: absolute;
    top: 0;
    left: 50%;
    height: 30px;
    width: 30px;
    margin: -15px 0 0 -15px;
    text-decoration: none;
  }

  .feed-picture {
    display: block;
    height: 200px;
    background: $color-gray-dark no-repeat center center;
    background-size: cover;
  }

  .feed-message {
    display: block;
    background-color: $color-white;
    font-size: pxToRem(14px);
    hyphens: auto;
    min-height: 71px;
    padding: 8px 8px 0;
    text-align: center;
  }

  .feed-count {
    background-color: $color-white;
    font-size: pxToRem(13px);
    text-align: center;
    padding: 8px;

    span {
      display: inline-block;
      padding: 0 6px;

      i {
        color: $color-turquoise;
      }
    }
  }
}
