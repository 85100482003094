.view-activites {

    .view-items {

        @include media-breakpoint-up(md) {

            display:   flex;
            flex-wrap: wrap;
        }
    }

    .view-items + .view-items {

        border-top:  5px double lighten($color-turquoise, 25%);
        margin-top:  19px;
        padding-top: 24px;
    }


}

.plo-activite {

    position: relative;

    &__image {

        display:         flex;
        align-items:     center;
        justify-content: center;
        background:      white;
        padding:         10px;
        margin:          0 0 15px;
        height:          12.5rem;

        @media screen and(min-width: 800px) {
            float: right;
            margin: 0 0 10px 10px;
            height: 8.375rem;
        }

        img {
            display: block;
            width: auto;
            max-width: none;
            height: 100%;
        }
    }

    &__entete {

        box-sizing: border-box;
        width:      100%;
        padding:    10px 10px 0;
        margin:     0 0 1rem;
        background: $color-black;
        color:      white;

        * {
            color: inherit;
        }

        > *:last-child {
            padding-bottom: 10px;
        }
    }

    &__categorie {

        margin:    0 0 .25rem;
        font-size: 1rem;
    }

    &__title {

        margin:    0 0 .5rem;
        font-size: 2rem;
    }
    &__asso {

        padding:        0;
        margin:         0 0 .5rem;
        font:           1.4rem $font-family-sans-serif;
        text-transform: uppercase;
    }
    &__body {
        *:last-child {
            margin-bottom: 0;
        }
    }


    &__contacts,
    &__infos-structure,
    &__infos-pratiques {

        @include media-breakpoint-up(md) {

            box-sizing: border-box;
            width:      33.333%;
            padding:    10px;
        }
    }

    &__field {

        display:     flex;
        align-items: flex-start;
        margin-bottom: 10px;
    }

    &__public-cible-desc {
        margin-top: -10px;
    }

    &__icon {

        display:      block;
        width:        32px;
        margin-right: 10px;
        font-size:    24px;
        line-height:  22px;
        text-align:   center;

        &.icon-email-o {

            font-size:   20px;
            line-height: 26px;
        }

        &.icon-smartphone-o {
            font-size: 18px;
        }
    }


    &__contacts {

        ul, ul li {

            padding:    0;
            margin:     0;
        }

        ul {

            list-style: none;

        }

        li ~ li {

            margin-top: 1rem;

            &:before {

                display:       block;
                width:         25%;
                margin-bottom: 1rem;
                border-top:    1px solid rgba(0, 0, 0, .15);
                content:       '';
            }
        }

        .plo-activite__field {

            margin-bottom: .25rem;
        }
    }
}
