.node-type-lieu {
  .field-type-taxonomy-term-reference {
    font-size: pxToRem(14px);
    font-weight: $fw-semi-bold;
    text-transform: uppercase;

    @include media-breakpoint-up(lg) {
      font-size: pxToRem(17px);
    }

    div {
      display: inline-block;
    }

    .field-item ~ .field-item {
      &:before {
        color: $color-turquoise;
        content: '-';
        margin-left: 5px;
        margin-right: 5px;
      }
    }
  }

  .field-group-html-element {
    ~ .field-group-html-element {
      margin-top: 15px;
    }
  }

  .field-type-office-hours {
    .field-label {
      font-family: $headings-font-family;
      font-size: pxToRem(28px);
      font-weight: $fw-medium;
      line-height: 1.2;
      margin-bottom: .5rem;
      margin-top: 1rem;
    }
  }

  .group-location {
    margin-top: 1rem;

    .country-name,
    .location-hidden {
      display: none;
    }

    .email {
      margin-top: .5em;

      abbr {
        display: none;
      }
    }

    .tel {
      margin-top: .5em;

      abbr {
        text-decoration: none;
        cursor: default;
      }

      span.value + span.value:before {
        content: ', ';
      }
    }

    .field-name-field-websites {
      margin-top: .5em;
    }

    //.adr {
    //  display: flex;
    //  flex-direction: column;
    //
    //  > span,
    //  > div {
    //    order: 1;
    //  }
    //
      .street-address {
        span {
          display: block;
        }
      }
    //
    //  .tel {
    //    order: 2;
    //  }
    //
    //  .email {
    //    order: 3;
    //  }
    //}
  }
}
