#footer-before-first {
  .container {
    background-color: $color-white;

    @include media-breakpoint-up(lg) {
      &,
      .vivre-a-plan-les-ouates & {
        background-color: $color-blue-light;
      }
      .que-faire-a-plan-les-ouates & {
        background-color: $color-green-light;
      }
      .commune &,
      .la-commune &,
      .pv-du-conseil-municipal & {
        background-color: $color-red-light;
      }
      .participer & {
        background-color: $color-brown-light;
      }
    }
  }
}
