.breadcrumb {
  background-color: inherit;
  color: $color-black;
  font-size: pxToRem(13px);
  font-weight: 300;
  margin-bottom: 0;
  padding: .75rem 0;
  text-transform: uppercase;

  a {
    color: $color-black;
    display: inline-block;
    margin: 0 .25rem;

    &:first-of-type {
      margin-left: 0;
    }
  }
}

.crumbs-current-page {
  font-weight: 600;
  margin: 0 .25rem;
}
