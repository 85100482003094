.slick-prev, .slick-next {
  font-size: 30px;
  height: 30px;
  width: 30px;

  &:before {
    font-size: 30px;
  }
}

.slick-prev {
  left: -35px;
}

.slick-next {
  right: -35px;
}

#mobile__related-pages_,
.view-id-related_articles_ {
  .slick-track {
    display: flex;
  }
  .slick-slide {
    background-color: $color-white;
    height: auto;
    margin: 0 45px;
  }
  .slick-dots {
    background-color: $color-black;
    bottom: -49px;
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 15px 0;
  }
  .slick-prev {
    left: 5px;
  }
  .slick-next {
    right: 15px;
  }
  .slick-prev:before, .slick-next:before {
    font-size: 30px;
    opacity: 1;
  }
  .slick-dots li {
    margin: 0 10px;
  }
  .slick-dots li button:before {
    opacity: 1;

    &,
    .vivre-a-plan-les-ouates & {
      color: $color-blue;
    }
    .que-faire-a-plan-les-ouates & {
      color: $color-green;
    }
    .commune &,
    .la-commune &,
    .pv-du-conseil-municipal & {
      color: $color-red;
    }
    .participer & {
      color: $color-brown;
    }
  }
  .slick-dots li.slick-active button:before {
    color: $color-white;
    opacity: 1;
  }
}

.view-id-related_articles_ {
  .slick-list {
    @include media-breakpoint-only  (lg) {
      margin: 0 30px;
    }
  }
  .slick-slide {
    background-color: $color-turquoise;

    @include media-breakpoint-up(lg) {
      background-color: $color-yellow;
      margin: 0 15px;
    }

    @include media-breakpoint-up(xl) {
      &:first-of-type {
        margin-left: 0;
      }

      &:last-of-type {
        margin-right: 0;
      }
    }
  }
  .slick-dots {
    background-color: $color-yellow;

    @include media-breakpoint-up(lg) {
      background-color: transparent;
    }
  }
  .slick-prev:before, .slick-next:before {
    color: $color-black;
  }
  .slick-dots li button:before {
    color: $color-turquoise;
  }
  .slick-dots li.slick-active button:before {
    color: $color-black;
  }
}

/**
Carousel
 */
.field-name-field-carousel {
  padding: 0 30px;

  .slick-prev:before, .slick-next:before {
    color: $color-black;
  }
}
